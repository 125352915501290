const apiConstant = {
  adminLogin: "v1/admin/login",
  bannerAdd: "v1/admin/banner/store",
  bannerList: "v1/admin/banner/list",
  bannerDetails: "v1/admin/banner/view",
  bannerEdit: "v1/admin/banner/update",
  bannerDelete: "v1/admin/banner/destroy",
  bannerChangeStatus: "v1/admin/banner/change-status",
  userList: "v1/admin/user/list",
  userDetails: "v1/admin/user/view",
  transactionList: "v1/admin/transaction/list",
  transactionDetails: "v1/admin/transaction/view",
  storeLocatorList: "v1/admin/store/list",
  storeLocatorDetails: "v1/admin/store/view",
  storeLocatorAdd: "v1/admin/store/store",
  storeLocatorEdit: "v1/admin/store/update",
  storeLocatorDelete: "v1/admin/store/destroy",
  storeLocatorChangeStatus: "v1/admin/store/change-status",
  stateList: "v1/state/list",
  cityList: "v1/city/list",
  generateQrCodeList: "v1/admin/generate-qr/list",
  generateQrCodeAdd: "v1/admin/generate-qr/store",
  generateQrCodeEdit: "v1/admin/generate-qr/update",
  generateQrCodeDelete: "v1/admin/generate-qr/destroy",
  generateQrCodeChangeStatus: "v1/admin/generate-qr/change-status",
  generateQrCodeDetails: "v1/admin/qrchunk/list",
  generateQrCodeBasicDetails: "v1/admin/generate-qr/view",
  generateQrCodeChuckList: "v1/admin/qrcode/list",
  editUserDetails: "v1/admin/profile/update",
  changePassword: "v1/admin/change-password",
  updateImage: "v1/admin/update-image",
  removeImage: "v1/admin/delete-image",
  removeBannerImage: "v1/admin/banner/delete-image",
  settingList: "v1/admin/setting/list",
  settingEdit: "v1/admin/setting/update",
  settingAdd: "v1/admin/setting/store",
  refreshToken: "v1/admin/refresh-token",
  inquiryList: "v1/admin/inquiry/list",
  inquiryDetails: "v1/admin/inquiry/view",
  inquiryChangeStatus: "v1/admin/inquiry/change-status",
  payoutRequestList: "v1/admin/transaction/payout-request-list",
  batchTransferStore: "v1/admin/batch-transfer/store",
  batchTransferList: "v1/admin/batch-transfer/list",
  dashboard: "v1/admin/dashboard",
}

export default apiConstant
