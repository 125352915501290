import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { addUpdateData, clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import Text from "common/PageComponent/Text"
import Dropdown from "common/PageComponent/Dropdown"
import labelConstant from "constants/label"
import Mobile from "common/PageComponent/Mobile"
import { useFormik } from "formik"
import * as Yup from "yup"
import DeleteModal from "components/Common/DeleteModal"
import Description from "common/PageComponent/Description"
import messageConstant from "constants/message"

const GenerateQrcode = props => {
  //meta title
  document.title = "Generate Qrcode | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
  })

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <Link
              to={`view/${cell.row.original.id}`}
              className="text-dark fw-bold"
            >
              {cell.row.original.title}
            </Link>
          )
        },
      },
      {
        header: "Points",
        accessorKey: "points",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "No Of QR",
        accessorKey: "no_of_qr",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Scan",
        accessorKey: "scan",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Unscan",
        accessorKey: "un_scan",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.row.original.status === "Published"
                  ? "success"
                  : "danger" && cellProps.row.original.status === "Draft"
                  ? "warning"
                  : "danger")
              }
            >
              {cellProps.row.original.status}
            </Badge>
          )
        },
      },
    ],
    []
  )

  const [isApiCall, setIsApiCall] = useState(0)
  const [generateQrcodeList, setGenerateQrcodeList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [generateQrcode, setGenerateQrcode] = useState("")
  const [addGenerateQrcode, setAddGenerateQrcode] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  /**Add Edit GenerateQrcode*/
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (generateQrcode && generateQrcode.id) || "",
      title: (generateQrcode && generateQrcode.title) || "",
      points: (generateQrcode && generateQrcode.points) || "",
      no_of_qr: (generateQrcode && generateQrcode.no_of_qr) || "",
      description: (generateQrcode && generateQrcode.description) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(messageConstant.TITLE_REQUIRED)
        .max(250, messageConstant.TITLE_INVALID)
        .matches(/^[a-zA-Z0-9-\s]+$/, messageConstant.TITLE_INVALID),
      points: Yup.string()
        .required(messageConstant.POINTS_REQUIRED)
        .max(250, messageConstant.POINTS_INVALID)
        .matches(/^[0-9\s]+$/, messageConstant.POINTS_INVALID),
      no_of_qr: Yup.string()
        .required(messageConstant.NO_OF_QR_REQUIRED)
        .max(250, messageConstant.NO_OF_QR_INVALID)
        .matches(/^[0-9\s]+$/, messageConstant.NO_OF_QR_INVALID),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateData = {
          id: generateQrcode.id,
          title: values["title"],
          points: values["points"],
          no_of_qr: values["no_of_qr"],
          description: values["description"],
        }
        dispatch(
          addUpdateData(
            updateData,
            props.router.navigate,
            api.generateQrCodeEdit
          )
        )
      } else {
        setAddGenerateQrcode("")
        const newData = {
          title: values["title"],
          points: values["points"],
          no_of_qr: values["no_of_qr"],
          description: values["description"],
        }
        dispatch(
          addUpdateData(newData, props.router.navigate, api.generateQrCodeAdd)
        )
      }
    },
  })
  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setGenerateQrcodeList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.generateQrCodeList))
  }

  if (!isEmpty(data) && !isEmpty(data.generateQrList) && updateListData) {
    setGenerateQrcodeList(data.generateQrList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Generate Qrcode", path: "/generate-qr" },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const handleClick = () => {
    validation.resetForm()
    setGenerateQrcode()
    setIsEdit(false)
    toggle()
  }

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.generateQrAdd) &&
    isEmpty(addGenerateQrcode)
  ) {
    setAddGenerateQrcode(data.generateQrAdd)
    validation.resetForm()
    toggle()
    getListData()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Generate Qrcode"
            breadcrumbItems={breadcrumbItem}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={generateQrcodeList || []}
                    isGlobalFilter={true}
                    isAddButton={true}
                    isCustomPageSize={true}
                    handleClicks={handleClick}
                    isPagination={true}
                    SearchPlaceholder={
                      listCount > 0
                        ? "Search in " + listCount + " records..."
                        : "Search in records..."
                    }
                    buttonClass="btn btn-primary  waves-effect waves-light mb-2 me-2"
                    buttonName="Add Generate Qrcode"
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    filterData={filterData}
                    getListData={getListData}
                    listCount={listCount}
                    isApiCall={isApiCall}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Generate Qr " : "Add Generate Qr"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="title"
                  label={labelConstant.TITLE}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="points"
                  label={labelConstant.POINTS}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="no_of_qr"
                  label={labelConstant.NO_OF_QR}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Description
                  validation={validation}
                  name="description"
                  label={labelConstant.Description}
                  row={3}
                  isRequire={false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    onClick={() => toggle()}
                    type="button"
                    className="btn btn-outline-danger  save-user"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={validation.isSubmitting ? true : false}
                    className="ms-2 btn btn-primary  save-user"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(GenerateQrcode)
