import React from "react"
import "flatpickr/dist/themes/material_blue.css"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { changeDateTimeFormat } from "common/commonFunction"

const BankTransactionDetails = ({ transactionView }) => {
  const jsonResponse = transactionView.payout_json_transfer_response_api
    ? JSON.parse(transactionView.payout_json_transfer_response_api)
    : ""
  return (
    <Row>
      <Col md={6}>
        <Card>
          <CardBody>
            <CardTitle> User Bank Details </CardTitle>
            <Row>
              {transactionView.user.is_bank == "Yes" ? (
                <>
                  <Col md={12} className="mt-2">
                    Is Bank : <strong>{transactionView.user.is_bank} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Bank Name :{" "}
                    <strong>
                      {transactionView.user.bank_name}{" "}
                      {transactionView.user.bank_verified == "Yes" ? (
                        <i className="mdi mdi-check-bold text-success ms-1"></i>
                      ) : (
                        <i className="mdi mdi-close-thick text-danger ms-1"></i>
                      )}
                    </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Branch Name :{" "}
                    <strong>{transactionView.user.branch_name} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Account Number:
                    <strong>{transactionView.user.account_number} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Account Name:{" "}
                    <strong>{transactionView.user.account_name} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    IFSC Code:{" "}
                    <strong>{transactionView.user.ifsc_code} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Bank Beneficiary ID:{" "}
                    <strong>{transactionView.user.bank_beneficiary_id} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    Bank Beneficiary Status:{" "}
                    <strong>
                      {transactionView.user.bank_beneficiary_status}{" "}
                    </strong>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={12} className="mt-2">
                    UPI Name: <strong>{transactionView.user.upi_name} </strong>
                  </Col>
                  <Col md={12} className="mt-2">
                    UPI ID: <strong>{transactionView.user.upi_id} </strong>
                    {transactionView.user.upi_verified == "Yes" ? (
                      <i className="mdi mdi-check-bold text-success ms-1"></i>
                    ) : (
                      <i className="mdi mdi-close-thick text-danger ms-1"></i>
                    )}
                  </Col>
                  <Col md={12} className="mt-2">
                    CashFree UPI Beneficiary ID:{" "}
                    <strong>{transactionView.user.upi_beneficiary_id} </strong>
                  </Col>
                </>
              )}

              <Col md={12} className="mt-2">
                Status: <strong>{transactionView.user.status} </strong>
              </Col>
              <Col md={12} className="mt-2">
                Updated At:{" "}
                <strong>
                  {transactionView.user.updated_date
                    ? changeDateTimeFormat(transactionView.user.updated_date)
                    : ""}{" "}
                </strong>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      {/* right side of the card */}
      <Col md={6}>
        <Card>
          <CardBody>
            <CardTitle>Transaction Details</CardTitle>
            <Col md={12} className="mt-2">
              Bank Pay Out Status:{" "}
              <span
                className={
                  transactionView.pay_out_status == "Completed"
                    ? "badge bg-success"
                    : "badge bg-danger"
                }
              >
                {transactionView.pay_out_status}
              </span>
            </Col>
            <Col md={12} className="mt-2">
              Payout Transfer ID:{" "}
              <strong>{transactionView.payout_transfer_id} </strong>
            </Col>
            <Col md={12} className="mt-2">
              Payout CF Transfer ID:{" "}
              <strong>{transactionView.payout_cf_transfer_id} </strong>
            </Col>
            <Col md={12} className="mt-2">
              Payout Transfer Mode:{" "}
              <strong>{transactionView.payout_transfer_mode} </strong>
            </Col>
            <Col md={12} className="mt-2">
              Payout Transfer UTR:{" "}
              <strong>{transactionView.payout_transfer_utr} </strong>
            </Col>
            <Col md={12} className="mt-2">
              Payout Status Description:{" "}
              <strong>{jsonResponse.status_description} </strong>
            </Col>
            {transactionView.user.is_bank == "Yes" ? (
              <>
                <Col md={12} className="mt-2">
                  Bank Name: <strong>{transactionView.bank_name} </strong>
                </Col>
                <Col md={12} className="mt-2">
                  Bank Branch Name:{" "}
                  <strong>{transactionView.bank_branch_name} </strong>
                </Col>
                <Col md={12} className="mt-2">
                  Bank Account Number:{" "}
                  <strong>{transactionView.bank_account_number} </strong>
                </Col>
                <Col md={12} className="mt-2">
                  Bank Account Name:{" "}
                  <strong>{transactionView.bank_account_name} </strong>
                </Col>
                <Col md={12} className="mt-2">
                  Bank IFSC Code:{" "}
                  <strong>{transactionView.bank_ifsc_code} </strong>
                </Col>
              </>
            ) : (
              <Col md={12} className="mt-2">
                UPI ID: <strong>{transactionView.upi_id} </strong>
              </Col>
            )}
            <Col md={12} className="mt-2">
              Created Date: <strong>{transactionView.created_date} </strong>
            </Col>
            <Col md={12} className="mt-2">
              Updated Date:{" "}
              <strong>
                {transactionView.updated_date
                  ? changeDateTimeFormat(transactionView.updated_date)
                  : ""}{" "}
              </strong>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
BankTransactionDetails.propTypes = {
  transactionView: PropTypes.object,
}
export default BankTransactionDetails
