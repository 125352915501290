import React, { Fragment, useEffect, useState } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import JobListGlobalFilter from "./GlobalSearchFilter"
import { get, isEmpty } from "lodash"
// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()
  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}
// Global Filter
const DebouncedInput = ({
  value: initialValue,
  filterData,
  getListData,
  isApiCall,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
    if (isApiCall == 1) {
      filterData.search = initialValue
      filterData.start = 0
      getListData()
    }
  }, [initialValue])
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)
    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])
  return (
    <React.Fragment>
      <Col sm={4}>
        <input
          {...props}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  buttonClass,
  buttonName,
  isAddButton,
  isCustomPageSize,
  handleClicks,
  isJobListGlobalFilter,
  filterData,
  getListData,
  listCount,
  isApiCall,
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank,
    })
    return itemRank.passed
  }
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const { getHeaderGroups, getRowModel } = table
  const [currentPage, setCurrentPage] = useState(1)
  let secondLastPage = Math.ceil(listCount / filterData.limit) - 1
  const perPageData = filterData.limit
  const handleClick = e => {
    setCurrentPage(Number(e.target.id))
    let start = Number(e.target.id - 1)
    filterData.start = filterData.limit * start
    getListData()
  }
  const pageNumbers = []
  const totalPages = Math.ceil(listCount / filterData.limit)
  for (let i = 1; i <= Math.ceil(listCount / filterData.limit); i++) {
    pageNumbers.push(i)
  }
  let newPageNumbers = []
  if (pageNumbers.length >= 10) {
    let startPage = currentPage - 5 > 0 ? currentPage - 5 : 1
    let endPage = currentPage + 5 <= totalPages ? currentPage + 5 : totalPages
    for (let i = startPage; i <= endPage; i++) {
      newPageNumbers.push(i)
    }
  }

  const handlePrevPage = event => {
    event.preventDefault()
    let prevPage = currentPage - 1
    setCurrentPage(prevPage)
    filterData.start = filterData.limit * (prevPage - 1)
    getListData()
  }
  const handleNextPage = event => {
    event.preventDefault()
    let nextPage = currentPage + 1
    setCurrentPage(nextPage)
    filterData.start = filterData.limit * (nextPage - 1)
    getListData()
  }
  const handleChangePageSize = pageSize => {
    filterData.start = 0
    filterData.limit = pageSize
    getListData()
  }
  const getRowClassName = row => {
    if (row.original.read === "No") {
      return "custom-bg-dark"
    }
    return ""
  }
  return (
    <Fragment>
      <Row className="mb-2">
        {isCustomPageSize && (
          <Col sm={2}>
            <select
              className="form-select pageSize mb-2"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
                handleChangePageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <DebouncedInput
            value={globalFilter ?? ""}
            filterData={filterData}
            getListData={getListData}
            isApiCall={isApiCall}
            onChange={value => setGlobalFilter(String(value))}
            className="form-control search-box me-2 mb-2 d-inline-block"
            placeholder={SearchPlaceholder}
          />
        )}
        {isJobListGlobalFilter && (
          <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />
        )}
        {isAddButton && (
          <Col sm={6}>
            <div className="text-sm-end">
              <Button
                type="button"
                className={buttonClass}
                onClick={handleClicks}
              >
                <i className="mdi mdi-plus me-1"></i> {buttonName}
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`${header.column.columnDef.enableSorting
                          ? "sorting sorting_desc"
                          : ""
                        }`}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.length > 0 ? (
              // Render table rows if there are rows available
              getRowModel().rows.map(row => (
                <tr key={row.id} className={getRowClassName(row)}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              // Render a "no data found" message if there are no rows
              <tr>
                <td
                  colSpan={getHeaderGroups()[0].headers.length}
                  className="text-center"
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {isPagination && (
        <Row className="justify-content-between align-items-end">
          {listCount > 0 && (
            <Col sm={12} md={5}>
              <div className="text-muted dataTables_info">
                Showing {data.length} of {listCount} entries
              </div>
            </Col>
          )}
          <div className={paginationWrapper}>
            <ul className={pagination}>
              <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
                <Link
                  className="page-link"
                  to="#"
                  onClick={e => handlePrevPage(e)}
                >
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageNumbers.length < 10 &&
                pageNumbers.map((item, index) => (
                  <li
                    className={
                      currentPage === item ? "page-item active " : "page-item"
                    }
                    key={index}
                  >
                    <Link
                      className="page-link"
                      to="#"
                      id={item}
                      onClick={e => handleClick(e)}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              {pageNumbers.length >= 10 &&
                newPageNumbers.map((item, index) => (
                  <li
                    className={
                      currentPage === item ? "page-item active " : "page-item"
                    }
                    key={index}
                  >
                    <Link
                      className="page-link"
                      to="#"
                      id={item}
                      onClick={e => handleClick(e)}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              <li
                className={`page-item ${currentPage > secondLastPage ? "disabled" : ""
                  }`}
              >
                <Link
                  className="page-link"
                  to="#"
                  onClick={e => handleNextPage(e)}
                >
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  )
}

export default TableContainer
