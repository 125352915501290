import Breadcrumbs from "components/Common/Breadcrumb2"
import withRouter from "components/Common/withRouter"
import api from "constants/api"
import messageConstant from "constants/message"
import { useFormik } from "formik"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row, Form, Card, CardBody } from "reactstrap"
import { addUpdateData, addUpdateDataMultipleForm } from "store/actions"
import * as Yup from "yup"
import PropTypes from "prop-types"
import Text from "common/PageComponent/Text"
import SubmitButton from "common/PageComponent/SubmitButton"
import DatePicker from "common/PageComponent/DatePicker"
import labelConstant from "constants/label"
import Description from "common/PageComponent/Description"
import Image from "common/PageComponent/Image"
import { isImageJpgOrPng, isImageSizeValid } from "common/commonFunction"
import moment from "moment"
import { get, isEmpty } from "lodash"
const AddEditForm = ({ bannerData, history, parameter, getListData }) => {
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))

  const [bannerAdd, setBannerAdd] = useState("")
  const [bannerUpdate, setBannerUpdate] = useState("")
  const [deleteBannerImageData, setDeleteBannerImageData] = useState("")
  const [isImageUploaded, setIsImageUploaded] = useState("no")
  const validation = useFormik({
    initialValues: {
      name: bannerData ? bannerData.name : "",
      from_date: bannerData ? bannerData.from_date : "",
      to_date: bannerData ? bannerData.to_date : "",
      terms_and_conditions: bannerData ? bannerData.terms_and_conditions : "",
      image: bannerData ? bannerData.image : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.NAME_REQUIRED)
        .max(250, messageConstant.NAME_INVALID),
      from_date: Yup.string().required(messageConstant.DATE_REQUIRED),
      to_date: Yup.string().required(messageConstant.DATE_REQUIRED),
      image: Yup.mixed()
        .required(messageConstant.IMAGE_REQUIRED)
        .test("fileFormat", messageConstant.IMAGE_TYPE, value => {
          if (typeof value == "string") {
            return true
          } else {
            return isImageJpgOrPng(value)
          }
        }),
    }),
    onSubmit: values => {
      values.from_date = values.from_date
        ? moment(values.from_date).format("YYYY-MM-DD")
        : ""
      values.to_date = values.to_date
        ? moment(values.to_date).format("YYYY-MM-DD")
        : ""
      if (bannerData) {
        values.id = bannerData.id
        if (typeof values.image === "string") {
          values.image = ""
        }
        dispatch(addUpdateDataMultipleForm(values, "", api.bannerEdit))
      } else {
        dispatch(addUpdateDataMultipleForm(values, "", api.bannerAdd))
      }
    },
  })

  if (!isEmpty(data) && !isEmpty(data.bannerAdd) && isEmpty(bannerAdd)) {
    setBannerAdd(data.bannerAdd)
    history("/banner")
  }
  if (!isEmpty(data) && !isEmpty(data.bannerUpdate) && isEmpty(bannerUpdate)) {
    setBannerUpdate(data.bannerUpdate)
    history("/banner/")
  }

  const deleteBannerImage = () => {
    setDeleteBannerImageData("")
    const updateData = {
      id: parameter.id,
    }
    dispatch(addUpdateData(updateData, "", api.removeBannerImage))
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.bannerDeleteImage) &&
    isEmpty(deleteBannerImageData)
  ) {
    setDeleteBannerImageData(data.bannerDeleteImage)
    validation.setFieldValue("image", "")
    getListData()
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col lg={4}>
                  <Text
                    validation={validation}
                    name="name"
                    label={labelConstant.NAME}
                    isRequire={true}
                  />
                </Col>
                <Col lg={4}>
                  <DatePicker
                    validation={validation}
                    name="from_date"
                    label={labelConstant.FROM_DATE}
                    isRequire={true}
                  />
                </Col>
                <Col lg={4}>
                  <DatePicker
                    validation={validation}
                    name="to_date"
                    label={labelConstant.TO_DATE}
                    isRequire={true}
                  />
                </Col>
                <Col lg={12}>
                  <Description
                    validation={validation}
                    name="terms_and_conditions"
                    label={labelConstant.DESCRIPTION}
                    row={3}
                    isRequire={false}
                  />
                </Col>
                <Col lg={6}>
                  <Image
                    validation={validation}
                    name="image"
                    label="Image"
                    isRequire={true}
                    selectedImage={bannerData ? bannerData.image : ""}
                    deleteImage={deleteBannerImage}
                  />
                </Col>
              </Row>
              <SubmitButton label="Submit" />
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
AddEditForm.propTypes = {
  bannerData: PropTypes.object,
  history: PropTypes.func,
  parameter: PropTypes.object,
  getListData: PropTypes.func,
}

export default AddEditForm
