import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import logoLightSvg from "../../assets/images/logo-light.svg"
// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import api from "constants/api"
import CarouselPage from "./CarouselPage"
import { loginUser } from "store/actions"
import Text from "common/PageComponent/Text"
import Password from "common/PageComponent/Password"

const Login = props => {
  //meta title
  document.title = `Login | ${process.env.REACT_APP_NAME}`

  const dispatch = useDispatch()

  const [rememberMe, setRememberMe] = useState(false)
  useEffect(() => {
    if (
      localStorage.getItem("rememberedEmail") &&
      localStorage.getItem("rememberedPassword")
    ) {
      setRememberMe(true)
    }
  }, [])
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem("rememberedEmail")
        ? localStorage.getItem("rememberedEmail")
        : "",
      password: localStorage.getItem("rememberedPassword")
        ? localStorage.getItem("rememberedPassword")
        : "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", values.email)
        localStorage.setItem("rememberedPassword", values.password)
      } else {
        localStorage.removeItem("rememberedEmail")
        localStorage.removeItem("rememberedPassword")
      }
      dispatch(loginUser(values, props.router.navigate, api.adminLogin))
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block card-logo">
                        <img
                          src={logoLightSvg}
                          alt=""
                          className="logo-dark-element"
                        />
                        <img
                          src={logoLightSvg}
                          alt=""
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to {process.env.REACT_APP_NAME}.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Text
                            validation={validation}
                            name="email"
                            label="Email"
                            isRequire={true}
                          />

                          <Password
                            validation={validation}
                            name="password"
                            label="Password"
                            isRequire={true}
                          />

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="rememberMe"
                              onChange={e => setRememberMe(e.target.checked)}
                              defaultChecked={
                                localStorage.getItem("rememberedEmail") &&
                                localStorage.getItem("rememberedPassword")
                                  ? true
                                  : rememberMe
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()}{" "}
                        {process.env.REACT_APP_NAME}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
