import PropTypes from "prop-types"
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react"
const Description = ({ validation, name, label, row, isRequire }) => {
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequire && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        className="form-control"
        placeholder={"Enter " + label}
        type="textarea"
        row={row}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        invalid={validation.errors[name]}
      />
      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

Description.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  row: PropTypes.number,
  isRequire: PropTypes.bool,
}

export default Description
