import React from "react"
import { Col, Row } from "reactstrap"
import api from "constants/api"
import { useFormik } from "formik"
import * as Yup from "yup"
import Image from "common/PageComponent/Image"
import messageConstant from "constants/message"
import { addUpdateDataMultipleForm } from "store/actions"
import labelConstant from "constants/label"
import PropTypes from "prop-types"
import { checkImageDimension, isImageJpgOrPng } from "common/commonFunction"
import { Form } from "react-router-dom"
import withRouter from "components/Common/withRouter"

const ProfileImageUpdate = ({ validation, userDetails, deleteImage }) => {
  return (
    <>
      <Row>
        <Col md="12">
          <Image
            validation={validation}
            name="profile_image"
            label={labelConstant.PROFILE_IMAGE}
            isRequire={false}
            selectedImage={
              userDetails && userDetails.profile_image
                ? userDetails.profile_image
                : ""
            }
            deleteImage={deleteImage}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12 mt-3 text-end">
          <button type="submit" className="btn btn-primary btn-sm">
            Update
          </button>
        </Col>
      </Row>
    </>
  )
}
ProfileImageUpdate.propTypes = {
  validation: PropTypes.object,
  userDetails: PropTypes.object,
  deleteImage: PropTypes.func,
}
export default ProfileImageUpdate
