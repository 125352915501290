import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changePreloader,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import "../../components/CommonForBoth/rightbar.scss"

import { layoutModeTypes } from "../../constants/layout"

const RightSidebar = props => {
  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showRightSidebarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Settings</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts Mode</span>
                <input
                  type="radio"
                  id="radioLight"
                  name="radioLight"
                  value={layoutModeTypes.LIGHT}
                  checked={props.layoutModeType === layoutModeTypes.LIGHT}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value)
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioLight">
                  Light
                </label>
                <input
                  type="radio"
                  id="radioDark"
                  name="radioDark"
                  value={layoutModeTypes.DARK}
                  checked={props.layoutModeType === layoutModeTypes.DARK}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value)
                    }
                  }}
                />
                <label htmlFor="radioDark">Dark</label>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  layoutModeType: PropTypes.any,
  changeLayoutMode: PropTypes.func,
  showRightSidebarAction: PropTypes.func,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  return { ...state.Layout }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar)
