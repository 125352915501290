import Breadcrumbs from "components/Common/Breadcrumb2"
import withRouter from "components/Common/withRouter"
import api from "constants/api"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container } from "reactstrap"
import AddEditForm from "./AddEditForm"
import { clearData, getData } from "store/actions"
const Banner = props => {
  document.title = "Banner | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const navigate = props.router.navigate
  const parameter = props.router.params
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params.id ? props.router.params.id : "",
  })
  const [bannerData, setBannerData] = useState("")
  const [isApiCall, setIsApiCall] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.bannerDetails))
  }

  if (!isEmpty(data) && !isEmpty(data.bannerView) && updateListData) {
    setBannerData(data.bannerView)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Banner", path: "/banner" },
    { title: "Banner Add", path: "/banner/add" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Banner" breadcrumbItems={breadcrumbItem} />

          {bannerData && (
            <AddEditForm
              bannerData={bannerData}
              history={navigate}
              parameter={parameter}
              getListData={getListData}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Banner)
