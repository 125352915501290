import "flatpickr/dist/themes/material_blue.css"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import QRCode from "react-qr-code"
import { changeDateTimeFormat } from "common/commonFunction"
const ScanDetails = ({ transactionView }) => {
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row>
              <div className="col-auto">
                {!isEmpty(transactionView.qr_code) ? (
                  <QRCode value={transactionView.qr_code} size={75} />
                ) : (
                  ""
                )}
              </div>
              <div className="col">
                <Row>
                  <Col md={4}>
                    <h4>{transactionView.generate_qr_title}</h4>
                  </Col>
                  <Col md={4}>
                    Points:
                    <span className="badge bg-primary font-size-12 ms-1">
                      {transactionView.points}
                    </span>
                  </Col>
                  <Col md={4}>
                    Type: <strong> {transactionView.type}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <h5>
                      {" "}
                      {transactionView.user
                        ? transactionView.user.name
                        : ""}{" "}
                    </h5>
                  </Col>
                  <Col md={4}>
                    Transaction Date:{" "}
                    <strong>
                      {transactionView.created_date
                        ? changeDateTimeFormat(transactionView.created_date)
                        : ""}
                    </strong>
                  </Col>
                </Row>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

ScanDetails.propTypes = {
  transactionView: PropTypes.object,
}

export default ScanDetails
