import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import CardBanner from "./CardList"
const Banner = props => {
  document.title = "Banner | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
  })
  const [banner, setBanner] = useState("")
  const [isApiCall, setIsApiCall] = useState(0)
  const [bannerList, setBannerList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [changeStatusBanner, setChangeStatusBanner] = useState("")
  const [deleteBanner, setDeleteBanner] = useState("")

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.bannerList))
  }

  if (!isEmpty(data) && !isEmpty(data.bannerList) && updateListData) {
    setBannerList(data.bannerList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Banner", path: "/banner" },
  ]

  /**Change Status */
  const handleStatusChange = (status, id) => {
    if (status == "Active") {
      status = "Inactive"
    } else {
      status = "Active"
    }
    const updateData = {
      status: status,
      id: id,
    }
    dispatch(
      addUpdateData(updateData, props.router.navigate, api.bannerChangeStatus)
    )
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.bannerChangeStatus) &&
    isEmpty(changeStatusBanner)
  ) {
    setChangeStatusBanner(data.bannerChangeStatus)
    window.location.reload()
  }

  //delete Banner
  const [deleteModal, setDeleteModal] = useState(false)
  const onClickDelete = banner => {
    setBanner(banner)
    setDeleteModal(true)
  }
  const handleDeleteBanner = () => {
    if (banner && banner.id) {
      const deleteData = {
        id: banner.id,
      }
      dispatch(
        addUpdateData(deleteData, props.router.navigate, api.bannerDelete)
      )
    }
  }

  if (!isEmpty(data) && !isEmpty(data.bannerDestroy) && isEmpty(deleteBanner)) {
    setDeleteModal(false)
    setDeleteBanner(data.bannerDestroy)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Banner" breadcrumbItems={breadcrumbItem} />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteBanner}
            modalTitle="Banner"
            onCloseClick={() => setDeleteModal(false)}
          />
          <Row className="mb-3">
            <Col lg="12">
              <div className="text-lg-end">
                <Link to={`add`} className="btn btn-primary">
                  <i className="mdi mdi-plus me-1"></i>Add New Banner
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            {(bannerList || [])?.map((singleBanner, key) => (
              <Col xl="4" key={"_banner_" + key}>
                <Link
                  to={`/banner/view/${singleBanner.id}`}
                  className="text-dark"
                >
                  <CardBanner
                    singleBanner={singleBanner}
                    onClickDelete={onClickDelete}
                    handleStatusChange={handleStatusChange}
                    listPage="Yes"
                  />
                </Link>
              </Col>
            ))}
          </Row>
          <Row>
            <Paginations
              perPageData={filterData.limit}
              data={bannerList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              listCount={listCount}
              getFilterData={getListData}
              filterData={filterData}
              isShowingPageLength={false}
              paginationDiv="col-12"
              paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Banner)
