import { call, put, takeEvery } from "redux-saga/effects"
import { fetchApiJSON } from "../../helpers/api"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  GET_DATA,
  ADD_UPDATE_DATA,
  LOGIN_USER,
  LOGOUT_USER,
  ADD_UPDATE_DATA_MULTIPLE_FORM,
} from "./actionTypes"
import {
  getDataFail,
  getDataSuccess,
  addUpdateDataSuccess,
  addUpdateDataFail,
  apiError,
} from "./actions"
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  toastClass: "toast-custom-style", // Add a custom class for styling
}
function* onAddUpdateData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("leonAuthUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200) {
      toastr["success"](response.MESSAGE)
      yield put(addUpdateDataSuccess(response.DATA))
    } else {
      sendFinalError(response, history)
      yield put(getDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* addUpdateDataMultipleForm({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("leonAuthUser"))
  // Create a new FormData object
  const formData = new FormData()
  // Append form fields to the FormData object
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  const options = {
    body: formData,
    method: "POST",
    headers: {
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200) {
      yield put(getDataSuccess(response.DATA))
    } else {
      sendFinalError(response, history)
      yield put(getDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* onGetData({ payload: { data, history, url } }) {
  const authData = JSON.parse(localStorage.getItem("leonAuthUser"))
  const options = {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData,
    },
  }
  const apiUrl = process.env.REACT_APP_API_URL + url
  try {
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200 || response.STATUS_CODE == 404) {
      yield put(getDataSuccess(response.DATA))
    } else {
      sendFinalError(response, history)
      yield put(getDataFail(response.MESSAGE))
    }
  } catch (error) {
    yield put(addUpdateDataFail(error))
  }
}
function* loginUser({ payload: { data, history, url } }) {
  try {
    const options = {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const apiUrl = process.env.REACT_APP_API_URL + url
    const response = yield call(fetchApiJSON, apiUrl, options)
    if (response.STATUS_CODE == 200) {
      localStorage.setItem("leonAuthUser", JSON.stringify(response.DATA.token))
      localStorage.setItem(
        "leonAuthUserDetails",
        JSON.stringify(response.DATA.userDetails)
      )
      localStorage.setItem(
        "leonUserPermissions",
        JSON.stringify(response.DATA.permission)
      )
      history("/dashboard")
      yield put(getDataSuccess(response.DATA))
    } else if (response.STATUS_CODE == 500) {
      toastr["error"](response.MESSAGE)
    } else {
      toastr["error"](response.MESSAGE)
      yield put(apiError(response.MESSAGE))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}
function* logoutUser({ payload: { history, url } }) {
  localStorage.removeItem("leonAuthUser")
  localStorage.removeItem("leonAuthUserDetails")
  history("/login")
}

function sendFinalError(response, history) {
  if (response.STATUS_CODE == 401) {
    // toastr["error"](response.MESSAGE)
    localStorage.removeItem("leonAuthUser")
    localStorage.removeItem("leonAuthUserDetails")
    history("/login")
  } else if (response.STATUS_CODE == 500) {
    toastr["error"](response.MESSAGE)
  } else if (response.STATUS_CODE == 408) {
    toastr["error"](response.MESSAGE)
    history("/dashboard")
  } else {
    toastr["error"](response.MESSAGE)
  }
}
function* appMasterSaga() {
  yield takeEvery(ADD_UPDATE_DATA, onAddUpdateData)
  yield takeEvery(GET_DATA, onGetData)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(ADD_UPDATE_DATA_MULTIPLE_FORM, addUpdateDataMultipleForm)
}
export default appMasterSaga
