import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
import { FormFeedback, Input, Label, InputGroup } from "reactstrap"
import React from "react"
const DatePicker = ({ validation, name, label, isRequire }) => {
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequire && <span className="text-danger">*</span>}
      </Label>
      <InputGroup>
        <Flatpickr
          className="form-control d-block"
          placeholder={"Enter " + label}
          options={{
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
          }}
          name={name}
          value={validation.values[name] || ""}
          onChange={date => {
            validation.setFieldValue(name, date[0])
          }}
        />
      </InputGroup>

      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

DatePicker.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
}

export default DatePicker
