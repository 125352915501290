import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { changeDateTimeFormat } from "common/commonFunction"

const PayoutRequest = props => {
  //meta title
  document.title = "Payout Request | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [checkedItems, setCheckedItems] = useState([])
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    role_id: 2,
    payout_status: "Request Received",
  })
  const columns = useMemo(
    () => [
      {
        header: "",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <div className="form-check">
              <input type="checkbox" onChange={() => handleCheckboxChange(cell.row.original.id)} />
            </div>
          )
        },
      },
      {
        header: "Name",
        accessorKey: "user.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.user.name ? (
                <div className="row align-items-center flex-nowrap">
                  <div className="col-auto">
                    {!cell.row.original.user.profile_image ? (
                      <>
                        {" "}
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {cell.row.original.user.name.charAt(0)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div>
                          <img
                            className="rounded-circle avatar-xs"
                            src={cell.row.original.user.name}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col ps-0">
                    <Link
                      to={"view/" + cell.row.original.id}
                      className="text-body fw-semibold"
                    >
                      {cell.row.original.user.name}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Points",
        accessorKey: "points",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Date",
        accessorKey: "created_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.created_date
                ? changeDateTimeFormat(cell.row.original.created_date)
                : ""}
            </>
          )
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cell => {
          return (
            <>
              <Link
                to={"view/" + cell.row.original.id}
                id="viewDataTooltip"
                className="text-body fw-semibold"
              >
                <i className="bx bx-show"></i>
              </Link>
              <UncontrolledTooltip target="viewDataTooltip">
                View
              </UncontrolledTooltip>
            </>
          )
        },
      },
    ],
    []
  )

  const [isApiCall, setIsApiCall] = useState(0)
  const [payoutRequestList, setPayoutRequestList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [pendingAmount, setPendingAmount] = useState(0)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setPayoutRequestList([])
    setListCount(0)
    setPendingAmount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.payoutRequestList))
  }

  if (!isEmpty(data) && !isEmpty(data.payoutRequestList) && updateListData) {
    setPayoutRequestList(data.payoutRequestList)
    setPendingAmount(data.payout_pending_amount)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Payout Request", path: "/payout-request" },
  ]
  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      checkedItems.filter(item => item !== value)
    } else {
      checkedItems.push(value);
    }
    setCheckedItems(checkedItems);
  };
  const [batchTransferStore, setBatchTransferStore] = useState()
  const paymentInitiate = () => {
    let transactionIdsString = checkedItems.join(",");
    if (transactionIdsString) {
      const requestData = {
        transaction_ids: transactionIdsString,
        type: "Initiate",
      }
      dispatch(clearData())
      dispatch(
        getData(requestData, props.router.navigate, api.batchTransferStore)
      )
    }
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.batchTransferStore) &&
    isEmpty(batchTransferStore)
  ) {
    setBatchTransferStore(data.batchTransferStore)
    props.router.navigate(
      "/payout-transaction/view/" + data.batchTransferStore.id
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Payout Request"
            breadcrumbItems={breadcrumbItem}
          />
          <Row>
            <Col md="3">
              <Card className="mini-stats-wid bg-success">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className={"text-white mb-2"}>Pending Amount</p>
                      <h5 className="mb-0 text-white">{pendingAmount}</h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3 text-end">
                        <button
                          onClick={paymentInitiate}
                          className="btn btn-primary"
                        >
                          Payment Initiate
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <TableContainer
                    columns={columns}
                    data={payoutRequestList || []}
                    isGlobalFilter={true}
                    isAddButton={false}
                    isCustomPageSize={true}
                    handleClick=""
                    isPagination={true}
                    SearchPlaceholder={
                      listCount > 0
                        ? "Search in " + listCount + " records..."
                        : "Search in records..."
                    }
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal"
                    buttonName=" "
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    filterData={filterData}
                    getListData={getListData}
                    listCount={listCount}
                    isApiCall={isApiCall}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PayoutRequest)
