import PropTypes from "prop-types"
import { FormFeedback, Input, Label } from "reactstrap"
import React from "react"
const Text = ({ validation, name, label, isRequire, readOnly }) => {
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequire && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        className="form-control"
        placeholder={"Enter " + label}
        type="text"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        invalid={validation.errors[name]}
        readOnly={readOnly}
      />
      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

Text.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default Text
