import React, { useEffect, useMemo, useState } from "react"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Collapse, Row, Label, Input } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import UserPersonalDetail from "./UserPersonalDetail"
import BankDetail from "./BankDetail"
import CardDetail from "pages/Transaction/CardDetail"
import UPIDetails from "./UPIDetails"

const UserDetails = props => {
  //meta title
  document.title = "User Details | " + process.env.REACT_APP_NAME
  const [activeTab, setactiveTab] = useState("1")
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const [selectedOption, setSelectedOption] = useState("")

  const handleOptionChange = event => {
    setSelectedOption(event.target.value)
  }
  const [col5, setcol5] = useState(false)
  const t_col5 = () => {
    setcol5(!col5)
  }
  const [selectDate, setSelectDate] = useState()
  const dateChange = date => {
    setSelectDate(date)
  }
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params ? props.router.params.id : "",
  })
  const [isApiCall, setIsApiCall] = useState(0)
  const [userView, setUserView] = useState("")
  const [transactionList, setTransactionList] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [updateData, setUpdateData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getUserData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getUserData = () => {
    setUpdateData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.userDetails))
  }

  if (!isEmpty(data) && !isEmpty(data.userView) && updateData) {
    setUserView(data.userView)
    setUpdateData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "User", path: "/user" },
    { title: "User Details", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="User Details" breadcrumbItems={breadcrumbItem} />

          <Row>
            <Col xl={6}>
              <UserPersonalDetail userDetail={userView} />
            </Col>
            <Col xl={6}>
              <BankDetail userDetail={userView} />
            </Col>
            <Col xl={6}>
              <UPIDetails userDetail={userView} />
            </Col>
          </Row>

          <CardDetail userId={props.router.params.id} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserDetails)
