import PropTypes from "prop-types"
import { FormFeedback, Input, Label, Button } from "reactstrap"
import React, { useState, useEffect } from "react"

const Password = ({ validation, name, label, isRequire }) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequire && <span className="text-danger">*</span>}
      </Label>
      <div className="input-group auth-pass-inputgroup">
        <Input
          name={name}
          className="form-control"
          placeholder={"Enter " + label}
          type={showPassword ? "text" : "password"}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[name] || ""}
          invalid={validation.errors[name]}
        />
        <Button
          type="button"
          color="light"
          onClick={togglePasswordVisibility}
          className={
            showPassword
              ? "mdi mdi-eye ml-2 btn btn-light"
              : "mdi mdi-eye-off ml-2 btn btn-light"
          }
        ></Button>
      </div>
      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

Password.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
}

export default Password
