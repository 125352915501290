import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const { title, breadcrumbItems } = props
  const itemLength = breadcrumbItems.length
  const location = useLocation()
  const currentPath = location.pathname
  const fullUrl = window.location.href
  const appUrl = fullUrl.split(currentPath)[0]
  let newBreadcrumbItems = []
  if (breadcrumbItems.length > 4) {
    // Create a new array with the last three elements and the first element
    newBreadcrumbItems = [breadcrumbItems[0], ...breadcrumbItems.slice(-3)]
  } else {
    newBreadcrumbItems = breadcrumbItems
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{title}</h4>
          <div className="page-title-right d-none d-md-block">
            <ol className="breadcrumb m-0">
              {newBreadcrumbItems.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  <Link
                    className={
                      key + 1 === itemLength ? "" : "breadcrumbItemColor"
                    }
                    to={item.path == "#" ? item.path : appUrl + item.path}
                  >
                    {item.title}
                  </Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
}

export default Breadcrumb
