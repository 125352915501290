import React from "react"
import { Col, FormFeedback, Input, Label, Row } from "reactstrap"
import api from "constants/api"
import { useFormik } from "formik"
import * as Yup from "yup"
import Image from "common/PageComponent/Image"
import messageConstant from "constants/message"
import { addUpdateDataMultipleForm } from "store/actions"
import labelConstant from "constants/label"
import PropTypes from "prop-types"
import Text from "common/PageComponent/Text"
import { Form } from "react-router-dom"
const BasicUserDetailUpdate = ({ validation }, props) => {
  return (
    <Row>
      <Col>
        <div className="mt-4">
          <Row>
            <Col>
              <Text
                validation={validation}
                name="name"
                label={labelConstant.NAME}
                isRequire={true}
              />
              <Text
                validation={validation}
                name="email"
                label={labelConstant.EMAIL}
                isRequire={true}
                readOnly={true}
              />
              <Text
                validation={validation}
                name="mobile"
                label={labelConstant.MOBILE}
                isRequire={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12 mt-3 text-end">
              <button type="submit" className="btn btn-primary btn-sm">
                Submit
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

BasicUserDetailUpdate.propTypes = {
  validation: PropTypes.object,
}

export default BasicUserDetailUpdate
