import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"

const StoreLocator = props => {
  document.title = "Store Locator | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params ? props.router.params.id : "",
  })
  const [isApiCall, setIsApiCall] = useState(0)
  const [storeLocatorView, setStoreLocatorView] = useState("")
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(
      getData(filterData, props.router.navigate, api.storeLocatorDetails)
    )
  }

  if (!isEmpty(data) && !isEmpty(data.storeView) && updateListData) {
    setStoreLocatorView(data.storeView)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Store Locator", path: "/store-locator" },
    { title: "Store Locator Details", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Store Locator" breadcrumbItems={breadcrumbItem} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      {!isEmpty(storeLocatorView.profile_image) ? (
                        <>
                          <img
                            className="avatar-md rounded-circle img-thumbnail"
                            src={storeLocatorView.profile_image}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          {storeLocatorView.name ? (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle bg-primary fs-1">
                                {storeLocatorView.name.charAt(0)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <Row>
                        <Col md={4}>
                          <h5>{storeLocatorView.name}</h5>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <i className="fas fa-phone-alt me-1"></i>
                            {storeLocatorView.mobile}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <i className="fas fa-envelope me-1"></i>
                            {storeLocatorView.email}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">City :</span>
                            {storeLocatorView.city
                              ? storeLocatorView.city.name
                              : ""}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">State :</span>
                            {storeLocatorView.state
                              ? storeLocatorView.state.name
                              : ""}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">Zip Code :</span>
                            {storeLocatorView.zip_code}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">Latitude :</span>
                            {storeLocatorView.latitude}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">Longitude :</span>
                            {storeLocatorView.longitude}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="d-flex ">
                          <span className="me-1 text-nowrap">Address :</span>
                          <p className="mb-1">{storeLocatorView.address}</p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(StoreLocator)
