import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Label,
  Input,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import api from "constants/api"
import Paginations from "components/Common/Pagination"
import Select from "react-select"
import BasicQrDetails from "./BsicQrDetail"
import QRCode from "react-qr-code"
import { changeDateFormat } from "common/commonFunction"
const GenarateDetails = props => {
  //meta title
  document.title = "Genarate QR Details | " + process.env.REACT_APP_NAME
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    generate_qr_id: props.router.params ? props.router.params.id : "",
    start: configJs.api_parameter.START,
    limit: 100,
    search: "",
  })
  const [activeTab, setActiveTab] = useState("1")
  const [selectedDropdown, setSelectedDropdown] = useState("")
  const [optionGroup, setOptionGroup] = useState([
    { value: "Published", label: "Published" },
    { value: "Draft", label: "Draft" },
    { value: "Inactive", label: "Inactive" },
  ])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageQr, setCurrentPageQr] = useState(1)
  const [qrCodeList, setQrCodeList] = useState([])
  const [listCountQrCode, setListCountQrCode] = useState(0)
  const [col5, setCol5] = useState(false)
  const [filterDataQrcode, setFilterDataQrcode] = useState({
    generate_qr_id: props.router.params ? props.router.params.id : "",
    start: configJs.api_parameter.START,
    limit: 100,
    search: "",
    status: "",
    qr_code: "",
  })
  const [options, setOptions] = useState([
    { value: "Unscan", label: "Unscan" },
    { value: "Scan", label: "Scan" },
  ])
  const handleChange = selectedOption => {
    setFilterDataQrcode({ ...filterDataQrcode, status: selectedOption.value })
    setFilterSearch(selectedOption)
  }
  const t_col5 = () => {
    setCol5(!col5)
  }

  const [isApiCall, setIsApiCall] = useState(0)
  const [qrcodeChunkList, setQrcodeChunkList] = useState("")
  const [listCount, setListCount] = useState(0)
  const [title, setTitle] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [generateQrChangeStatus, setGenerateQrChangeStatus] = useState("")
  const [qrcodeUpdateListData, setQrcodeUpdateListData] = useState(false)
  const [filterSearch, setFilterSearch] = useState("")
  const [isApiCallQr, setIsApiCallQr] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])
  useEffect(() => {
    if (isApiCallQr == 0) {
      getQrCodeListData()
      setIsApiCallQr(1)
    }
  }, [isApiCallQr])

  const getListData = () => {
    setQrcodeChunkList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(
      getData(filterData, props.router.navigate, api.generateQrCodeDetails)
    )
  }

  const getQrCodeListData = () => {
    setQrCodeList([])
    setListCountQrCode(0)
    setQrcodeUpdateListData(true)
    dispatch(clearData())
    dispatch(
      getData(
        filterDataQrcode,
        props.router.navigate,
        api.generateQrCodeChuckList
      )
    )
  }

  if (!isEmpty(data) && !isEmpty(data.qrChunkList) && updateListData) {
    setQrcodeChunkList(data.qrChunkList)
    setListCount(data.filter_count)
    setTitle(data.title)
    setSelectedDropdown({
      value: data.status,
      label: data.status,
    })
    setUpdateListData(false)
  }
  if (!isEmpty(data) && !isEmpty(data.qrCodeList) && qrcodeUpdateListData) {
    setQrCodeList(data.qrCodeList)
    setListCountQrCode(data.filter_count)
    setQrcodeUpdateListData(false)
  }

  const toggleCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Genarate QR", path: "/generate-qr" },
    { title: "Genarate QR Chunk", path: "#" },
  ]
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "#ced4da",
    }),
    menu: base => ({
      ...base,
      borderRadius: 0.25,
      marginTop: 0,
      borderColor: "#ced4da",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      backgroundColor: "#fff",
      borderColor: "#ced4da",
    }),
  }
  const handleDropdown = selectedDropdown => {
    setSelectedDropdown(selectedDropdown)
    let status = selectedDropdown.value
    const updateData = {
      status: status,
      id: props.router.params.id,
    }
    dispatch(
      addUpdateData(
        updateData,
        props.router.navigate,
        api.generateQrCodeChangeStatus
      )
    )
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.generateQrChangeStatus) &&
    isEmpty(generateQrChangeStatus)
  ) {
    setGenerateQrChangeStatus(data.generateQrChangeStatus)
  }

  const handleDownloadPdf = pdfUrl => {
    window.open(pdfUrl, "_blank")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Genarate QR Chunk"
            breadcrumbItems={breadcrumbItem}
          />
          <BasicQrDetails qrId={props.router.params.id} />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <CardText>Change Status</CardText>
                  <Row>
                    <Col md={3}>
                      <Select
                        value={selectedDropdown}
                        onChange={handleDropdown}
                        options={optionGroup}
                        className="select2-selection"
                        styles={customStyles}
                        placeholder={"Select Status"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Qrcode Chunk List
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Qrcode List</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          {(qrcodeChunkList || [])?.map((singleChunk, key) => (
                            <Card key={key}>
                              <CardBody>
                                <CardText>
                                  <Row>
                                    <Col>
                                      <p>
                                        Chunk Id : <b>{singleChunk.id}</b>
                                      </p>
                                    </Col>
                                    <Col>
                                      <p>
                                        Order : <b>{singleChunk.order}</b>
                                      </p>
                                    </Col>
                                    <Col>
                                      <p>
                                        No Of Qrcode :{" "}
                                        <b>{singleChunk.number}</b>
                                      </p>
                                    </Col>
                                    <Col>
                                      <p>
                                        Qr Code Generated
                                        <span
                                          className={
                                            singleChunk.qr_generated == "Yes"
                                              ? "badge bg-success font-size-12 ms-1"
                                              : "badge bg-danger font-size-12 ms-1"
                                          }
                                        >
                                          {" "}
                                          {singleChunk.qr_generated}
                                        </span>
                                      </p>
                                    </Col>
                                    {singleChunk.pdf_generated == "Yes" && (
                                      <Col>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() =>
                                            handleDownloadPdf(
                                              singleChunk.qr_generated_pdf_url
                                            )
                                          }
                                        >
                                          <i className="mdi mdi-download me-1"></i>{" "}
                                          Download PDF
                                        </button>
                                      </Col>
                                    )}
                                    {singleChunk.pdf_generated == "No" && (
                                      <Col>
                                        <p>
                                          Pdf Generated
                                          <b>
                                            <span
                                              className={
                                                singleChunk.status == "Pending"
                                                  ? "badge bg-warning font-size-12 ms-1"
                                                  : "badge bg-info font-size-12 ms-1"
                                              }
                                            >
                                              {" "}
                                              {singleChunk.status}
                                            </span>
                                          </b>
                                        </p>
                                      </Col>
                                    )}
                                    <Col>
                                      <Link
                                        to={`/generate-qr/view/${singleChunk.generate_qr_id}/generate-qr-chunk/${singleChunk.id}`}
                                        className="btn btn-primary btn-sm"
                                      >
                                        <i className="mdi mdi-eye me-1"></i>{" "}
                                        View Qr code
                                      </Link>
                                    </Col>
                                  </Row>
                                </CardText>
                              </CardBody>
                            </Card>
                          ))}

                          {listCount > 0 && (
                            <Paginations
                              perPageData={filterData.limit}
                              data={qrcodeChunkList}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              listCount={listCount}
                              getFilterData={getListData}
                              filterData={filterData}
                              isShowingPageLength={false}
                              paginationDiv="col-12"
                              paginationClass="pagination pagination-rounded mt-2 mb-5"
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="mb-3">
                        <Col lg={12} className="text-end">
                          <button
                            onClick={t_col5}
                            className="btn btn-primary ms-3"
                            type="button"
                          >
                            <i className="bx bx-filter-alt align-middle me-2"></i>
                            Filter
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Collapse isOpen={col5}>
                            <Card className="border">
                              <CardBody>
                                <Row>
                                  <Col>
                                    <Row>
                                      <Col lg={4}>
                                        <Label>QR Code</Label>
                                        <div className="search-box mb-2 me-2">
                                          <div className="position-relative">
                                            <Input
                                              type="text"
                                              className="form-control rounded"
                                              placeholder="QR Code"
                                              value={filterDataQrcode.qr_code}
                                              onChange={e =>
                                                setFilterDataQrcode({
                                                  ...filterDataQrcode,
                                                  qr_code: e.target.value,
                                                })
                                              }
                                            />
                                            <i className="bx bx-search-alt search-icon"></i>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="project-status-input">
                                            Status
                                          </Label>
                                          <Select
                                            className="select2-selection"
                                            styles={customStyles}
                                            placeholder="Select Status"
                                            value={filterSearch}
                                            options={options}
                                            onChange={handleChange}
                                          ></Select>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm="auto" className="align-self-center">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        getQrCodeListData()
                                      }}
                                    >
                                      Filter
                                    </button>
                                    <button
                                      className="btn btn-secondary ms-2"
                                      type="button"
                                      onClick={() => {
                                        filterDataQrcode.status = ""
                                        filterDataQrcode.qr_code = ""
                                        setFilterSearch("")
                                        getQrCodeListData()
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          {!isEmpty(qrCodeList) &&
                            qrCodeList.length > 0 &&
                            qrCodeList.map((item, key) => (
                              <Card key={key}>
                                <CardBody>
                                  <div className="d-flex">
                                    <div className="me-3">
                                      <QRCode value={item.qr_code} size={50} />
                                    </div>
                                    <div className="flex-grow-1 align-self-center">
                                      <Row className="align-items-center">
                                        <Col>
                                          <p className="mb-0">
                                            <b>{item.qr_code}</b>
                                          </p>
                                        </Col>
                                        <Col>
                                          <h5 className="mb-2">
                                            {item.user ? item.user.name : ""}
                                          </h5>
                                          {item.scan_at_new && (
                                            <p className="mb-0">
                                              <i className="bx bx-calendar me-1"></i>
                                              {changeDateFormat(
                                                item.scan_at_new
                                              )}
                                            </p>
                                          )}
                                        </Col>
                                        <Col>
                                          <h5 className="mb-0 d-flex align-items-center">
                                            <span
                                              className={
                                                item.status == "Scan"
                                                  ? "badge bg-success font-size-12"
                                                  : "badge bg-secondary font-size-12"
                                              }
                                            >
                                              {item.status}
                                            </span>
                                            <div className="avatar-sm ms-2">
                                              <span className="avatar-title rounded-circle bg-light text-muted font-size-16">
                                                {item.points}
                                              </span>
                                            </div>
                                          </h5>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            ))}
                          {isEmpty(qrCodeList) && qrCodeList.length == 0 && (
                            <Card>
                              <CardBody>
                                <div className="text-center">
                                  <h4>No Data Found</h4>
                                </div>
                              </CardBody>
                            </Card>
                          )}
                        </Col>
                      </Row>
                      {listCountQrCode > 0 && (
                        <Paginations
                          perPageData={filterDataQrcode.limit}
                          data={qrCodeList}
                          currentPage={currentPageQr}
                          setCurrentPage={setCurrentPageQr}
                          listCount={listCountQrCode}
                          getFilterData={getQrCodeListData}
                          filterData={filterData}
                          isShowingPageLength={false}
                          paginationDiv="col-12"
                          paginationClass="pagination pagination-rounded mt-2 mb-5"
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(GenarateDetails)
