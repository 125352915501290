import React from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
// flatpickr
import withRouter from "components/Common/withRouter"
import "flatpickr/dist/themes/material_blue.css"
import CardDetail from "./CardDetail"

const Transaction = props => {
  //meta title
  document.title = "Transaction | " + process.env.REACT_APP_NAME

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Transaction", path: "/transaction" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Transaction" breadcrumbItems={breadcrumbItem} />
          <CardDetail
            userId=""
            batch_id={
              props.router.params.batch_id ? props.router.params.batch_id : ""
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Transaction)
