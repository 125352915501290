import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Input, Label, Modal, Row } from "reactstrap"
import withRouter from "components/Common/withRouter"

const User = props => {
  //meta title
  document.title = "User | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    role_id: 2,
  })
  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.name ? (
                <div className="row align-items-center flex-nowrap">
                  <div className="col-auto">
                    {!cell.row.original.profile_image ? (
                      <>
                        {" "}
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {cell.row.original.name.charAt(0)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div>
                          <img
                            className="rounded-circle avatar-xs"
                            src={cell.row.original.profile_image}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col">
                    <Link
                      to={`view/${cell.row.original.id}`}
                      className="text-secondary"
                    >
                      {cell.row.original.name}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.mobile ? (
                <Link
                  to={`view/${cell.row.original.id}`}
                  className="text-secondary"
                >
                  {cell.row.original.mobile}
                </Link>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "City",
        accessorKey: "city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "State",
        accessorKey: "state",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cell => {
          return (
            <Link
              to={`view/${cell.row.original.id}`}
              className="text-secondary"
              id={`view${cell.row.original.id}`}
            >
              <i className="mdi mdi-eye"></i>
            </Link>
          )
        },
      },
    ],
    []
  )

  const [isApiCall, setIsApiCall] = useState(0)
  const [userList, setUserList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUserList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.userList))
  }

  if (!isEmpty(data) && !isEmpty(data.userList) && updateListData) {
    setUserList(data.userList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "User", path: "/user" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="User" breadcrumbItems={breadcrumbItem} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={userList || []}
                    isGlobalFilter={true}
                    isAddButton={false}
                    isCustomPageSize={true}
                    handleClick=""
                    isPagination={true}
                    SearchPlaceholder={
                      listCount > 0
                        ? "Search in " + listCount + " records..."
                        : "Search in records..."
                    }
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal"
                    buttonName=" "
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    filterData={filterData}
                    getListData={getListData}
                    listCount={listCount}
                    isApiCall={isApiCall}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(User)
