import PropTypes from "prop-types"
import { Card, Col, FormFeedback, Label, Row } from "reactstrap"
import React, { useState } from "react"
import labelConstant from "constants/label"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
const Image = ({
  validation,
  name,
  label,
  isRequire,
  selectedImage,
  deleteImage,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
    validation.setFieldValue(name, files[0])
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <Row>
      <Col md={selectedFiles.length == 0 && !selectedImage ? 12 : 6}>
        <>
          <Label className="form-label">
            {label}
            {isRequire && <span className="text-danger">*</span>}
          </Label>
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="dropzone"
                style={{ minHeight: "80px", width: "230px" }}
              >
                <div className="dz-message needsclick p-3" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4 className="font-size-12">
                    Drop files here or click to upload.
                  </h4>
                </div>
              </div>
            )}
          </Dropzone>
        </>
      </Col>

      {!isEmpty(selectedImage) ? (
        <Col md="6">
          <Label className="form-label">Upload Image</Label>
          <div className="mt-2">
            <img
              data-dz-thumbnail=""
              className="img-thumbnail previewAvatar_image"
              alt={selectedImage}
              src={selectedImage}
              width={200}
            />
            <div className="text-muted font-weight-bold text-center mt-2">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={deleteImage}
              >
                <i className="mdi mdi-delete me-1"></i>Delete
              </button>
            </div>
          </div>
        </Col>
      ) : (
        <Col md="6">
          <Label className="form-label"></Label>
          {selectedFiles.map((f, i) => {
            return (
              <div className="mt-2" key={i + "-file"}>
                <img
                  data-dz-thumbnail=""
                  className="img-thumbnail previewAvatar_image"
                  alt={f.name}
                  src={f.preview}
                  width={200}
                />
                <div className="text-muted font-weight-bold">
                  {f.name}

                  <i
                    className="mdi mdi-close-circle-outline me-1 ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedFiles([])
                      validation.setFieldValue("image", "")
                    }}
                  ></i>
                </div>
              </div>
            )
          })}
        </Col>
      )}
      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </Row>
  )
}

Image.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequire: PropTypes.bool,
  selectedImage: PropTypes.string,
  deleteImage: PropTypes.func,
  setIsImageUploaded: PropTypes.func,
}

export default Image
