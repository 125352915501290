import { useLocation } from "react-router-dom"
import moment from "moment"

export function isImageJpgOrPng(value) {
  try {
    return value && ["image/jpeg", "image/png"].includes(value.type)
  } catch (err) {
    return false
  }
}
export function isImageSizeValid(value, minWidth, minHeight) {
  try {
    return value.width <= minWidth && value.height <= minHeight
  } catch (err) {
    return false
  }
}
export function getCurrentPath() {
  const location = useLocation()
  const currentPath = location.pathname
  return currentPath
}
export function checkImageDimension(value, minWidth, minHeight) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => {
      const img = new Image()
      img.src = event.target.result

      img.onload = () => {
        const { naturalWidth, naturalHeight } = img
        if (naturalWidth >= minWidth && naturalHeight >= minHeight) {
          resolve(true) // Image dimensions are greater than 500x500
        } else {
          resolve(false) // Image dimensions are not greater than 500x500
        }
      }

      img.onerror = () => {
        reject(new Error("Failed to load image"))
      }
    }

    reader.onerror = () => {
      reject(new Error("Failed to read file"))
    }

    reader.readAsDataURL(value)
  })
}
export function changeDateFormat(date) {
  const parts = date.split("-")
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}` // Changing format to dd-mm-YY
  return formattedDate
}
export function changeDateTimeFormat(dateTime) {
  const date = dateTime.split(" ")
  const parts = date[0].split("-")
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}` // Changing format to dd-mm-YY
  return formattedDate + "  " + date[1]
}
export function formateDate(date, format) {
  const dateFormat = format ? format : "YYYY-MM-DD"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}
