import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { changeDateTimeFormat } from "common/commonFunction"

const BatchTransferList = props => {
  //meta title
  document.title = "Payout Transaction | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    role_id: 2,
    payout_status: "pending",
  })
  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Batch Transfer Id",
        accessorKey: "batch_transfer_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created Date",
        accessorKey: "batch_created_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>{changeDateTimeFormat(cell.row.original.batch_created_date)}</>
          )
        },
      },
      {
        header: "Total Payout Amount",
        accessorKey: "total_payout_amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Cf Batch Transfer Id",
        accessorKey: "cf_batch_transfer_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.status == "RECEIVED" ? (
                <span className="badge bg-warning font-size-12">
                  {cell.row.original.status}
                </span>
              ) : cell.row.original.status == "PROCESSED" ? (
                <span className="badge bg-success font-size-12">
                  {cell.row.original.status}
                </span>
              ):(
                <span className="badge bg-danger font-size-12">
                  {cell.row.original.status}
                </span>
              )}
            </>
          )
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cell => {
          return (
            <>
              <Link
                to={"view/" + cell.row.original.id}
                id="viewDataTooltip"
                className="text-body fw-semibold"
              >
                <i className="bx bx-show"></i>
              </Link>
              <UncontrolledTooltip target="viewDataTooltip">
                View
              </UncontrolledTooltip>
            </>
          )
        },
      },
    ],
    []
  )

  const [isApiCall, setIsApiCall] = useState(0)
  const [batchTransferList, setBatchTransferList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setBatchTransferList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.batchTransferList))
  }

  if (!isEmpty(data) && !isEmpty(data.batchTransferList) && updateListData) {
    setBatchTransferList(data.batchTransferList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Payout Transaction", path: "/payout-transaction" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Payout Transaction"
            breadcrumbItems={breadcrumbItem}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={batchTransferList || []}
                    isGlobalFilter={true}
                    isAddButton={false}
                    isCustomPageSize={true}
                    handleClick=""
                    isPagination={true}
                    SearchPlaceholder={
                      listCount > 0
                        ? "Search in " + listCount + " records..."
                        : "Search in records..."
                    }
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal"
                    buttonName=" "
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    filterData={filterData}
                    getListData={getListData}
                    listCount={listCount}
                    isApiCall={isApiCall}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BatchTransferList)
