import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { isEmpty, size } from "lodash"

const UserPersonalDetail = ({ userDetail }) => {
  return (
    <React.Fragment>
      <Card>
        <h5 className="card-header bg-transparent border-bottom ">
          Personal Details
        </h5>
        <CardBody>
          <div className="d-flex">
            <div className="me-3">
              {!isEmpty(userDetail.profile_image) ? (
                <>
                  <img
                    className="avatar-md rounded-circle img-thumbnail"
                    src={userDetail.profile_image}
                    alt=""
                  />
                </>
              ) : (
                <>
                  {userDetail.name ? (
                    <div className="avatar-md">
                      <span className="avatar-title rounded-circle bg-primary fs-1">
                        {userDetail.name.charAt(0)}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="flex-grow-1 align-self-center">
              <Row>
                <Col>
                  <h5>{userDetail.name}</h5>
                </Col>
                <Col>
                  <p className="mb-0">
                    <i className="fas fa-phone-alt me-1"></i>
                    {userDetail.mobile}
                  </p>
                </Col>

                <Col xl="auto">
                  {userDetail.email ? (
                    <p className="mb-0">
                      <i className="fas fa-envelope me-1"></i>
                      {userDetail.email}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="mb-0">
                    Type : <b>{userDetail.type}</b>
                  </p>
                </Col>
                {userDetail.state ? (
                  <Col>
                    <p className="mb-0">
                      <i className="fas fa-city me-1"></i>
                      {userDetail.state}
                    </p>
                  </Col>
                ) : (
                  ""
                )}
                {userDetail.city ? (
                  <Col>
                    <p className="mb-0">
                      <i className="fas fa-city me-1"></i>
                      {userDetail.city}
                    </p>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {userDetail.adhar_number ? (
                <Row>
                  <Col className="mt-1">
                    <p className="mb-0">
                      <span className="me-1">Aadhaar No :</span>
                      {userDetail.adhar_number}
                      <span className="ms-2">
                        {userDetail.aadhar_verified == "Yes" ? (
                          <i className="mdi mdi-check-bold text-success"></i>
                        ) : (
                          <i className="mdi mdi-close-thick text-danger"></i>
                        )}
                      </span>
                    </p>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row>
                <Col className="mt-1">
                  <p className="mb-0">
                    <span className="me-1">Balance :</span>
                    {userDetail.current_balance}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

UserPersonalDetail.propTypes = {
  userDetail: PropTypes.object,
}

export default UserPersonalDetail
