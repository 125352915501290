import PropTypes from "prop-types"
import { FormFeedback, Input, Label } from "reactstrap"
import React, { useState, useEffect } from "react"
import labelConstant from "constants/label"
import IntlTelInput from "react-intl-tel-input"
import "react-intl-tel-input/dist/main.css"
import Name from "./Text"
import { getData } from "store/actions"
import api from "../../constants/api"
import { useDispatch } from "react-redux"
const Mobile = ({ validation, name, label, isSendOtp, isRequire }) => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [mobileVerifyStatus, setMobileVerifyStatus] = useState(false)
  const [isMobileVerify, setIsMobileVerify] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (validation.initialValues[name] != "") {
      setPhoneNumber(
        "+" +
          validation.initialValues.country_code +
          validation.initialValues[name]
      )
    }
  }, [validation.initialValues[name]])

  const handlePhoneNumberChange = (status, phoneNumber, country) => {
    setPhoneNumber(phoneNumber)
    validation.setFieldValue(name, phoneNumber)
    validation.setFieldValue("country_code", country.dialCode)
    setMobileVerifyStatus(status)
  }
  const handlePhoneNumberBlur = () => {
    if (mobileVerifyStatus) {
      setIsMobileVerify(true)
      const requestData = {
        mobile: phoneNumber,
      }
      dispatch(getData(requestData, "", api.sendOtp))
    } else {
      setIsMobileVerify(false)
    }
  }
  return (
    <>
      <div className="mb-3 ">
        <Label className="form-label d-block">
          {label}
          {isRequire && <span className="text-danger">*</span>}
        </Label>
        <IntlTelInput
          containerClassName="intl-tel-input w-100"
          preferredCountries={["in"]}
          defaultCountry="in"
          inputClassName="form-control"
          type="tel"
          onPhoneNumberChange={handlePhoneNumberChange}
          onPhoneNumberBlur={handlePhoneNumberBlur}
          invalid={validation.errors[name] ? true : false}
          placeholder="Enter Mobile Number"
          value={
            phoneNumber.startsWith("0") ? phoneNumber.substr(1) : phoneNumber
          }
        />
        {validation.errors[name] ? (
          <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
        ) : null}
      </div>

      {isSendOtp && isMobileVerify && (
        <div className="mb-3">
          <Name
            validation={validation}
            name="otp"
            label={labelConstant.OTP}
            placeholder="Enter OTP"
          />
        </div>
      )}
    </>
  )
}

Mobile.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  isSendOtp: PropTypes.bool,
  isRequire: PropTypes.bool,
}

export default Mobile
