import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Card, CardBody, CardImg, CardText, Col, CardTitle } from "reactstrap"
import { size } from "lodash"
import { changeDateFormat } from "common/commonFunction"

const CardBanner = props => {
  const { singleBanner, onClickDelete, handleStatusChange, listPage } = props

  return (
    <React.Fragment>
      <Card>
        {singleBanner.image ? (
          <CardImg
            top
            width="100%"
            src={singleBanner.image}
            alt={singleBanner.name}
          />
        ) : (
          <span
            className="avatar-title bg-secondary"
            width="100%"
            style={{ height: "235px" }}
          >
            No Image
          </span>
        )}

        <CardBody>
          <CardTitle className="mb-3">
            {singleBanner.name}
            <Link
              to="#"
              onClick={() =>
                handleStatusChange(singleBanner.status, singleBanner.id)
              }
              className={
                singleBanner.status == "Active"
                  ? "badge bg-success  ms-1"
                  : "badge bg-danger  ms-1"
              }
            >
              {singleBanner.status}
            </Link>
            <span className="float-end">
              <Link
                to={`/banner/edit/${singleBanner.id}`}
                className="text-muted me-1"
              >
                <i className="mdi mdi-square-edit-outline font-size-18"></i>
              </Link>
              <Link
                to="#"
                onClick={() => onClickDelete(singleBanner)}
                className="text-muted"
              >
                <i className="mdi mdi-delete font-size-18"></i>
              </Link>
            </span>
          </CardTitle>
          <CardText>
            {singleBanner.from_date_new
              ? changeDateFormat(singleBanner.from_date_new)
              : ""}{" "}
            to{" "}
            {singleBanner.to_date_new
              ? changeDateFormat(singleBanner.to_date_new)
              : ""}
          </CardText>
          <CardText className={listPage == "Yes" ? "text-truncate" : ""}>
            {singleBanner.terms_and_conditions}
          </CardText>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

CardBanner.propTypes = {
  singleBanner: PropTypes.object,
  onClickDelete: PropTypes.func,
  handleStatusChange: PropTypes.func,
}

export default CardBanner
