import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_DATA,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  ADD_UPDATE_DATA,
  ADD_UPDATE_DATA_SUCCESS,
  ADD_UPDATE_DATA_FAIL,
  CLEAR_DATA,
  ADD_UPDATE_DATA_MULTIPLE_FORM,
} from "./actionTypes"

export const loginUser = (data, history, url) => {
  return {
    type: LOGIN_USER,
    payload: { data, history, url },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = (history, url) => {
  return {
    type: LOGOUT_USER,
    payload: { history, url },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getData = (data, history, url) => ({
  type: GET_DATA,
  payload: { data, history, url },
})

export const getDataSuccess = data => ({
  type: GET_DATA_SUCCESS,
  payload: { data },
})

export const getDataFail = error => ({
  type: GET_DATA_FAIL,
  payload: error,
})

export const addUpdateData = (data, history, url) => ({
  type: ADD_UPDATE_DATA,
  payload: { data, history, url },
})

export const addUpdateDataMultipleForm = (data, history, url) => ({
  type: ADD_UPDATE_DATA_MULTIPLE_FORM,
  payload: { data, history, url },
})

export const addUpdateDataSuccess = data => ({
  type: ADD_UPDATE_DATA_SUCCESS,
  payload: data,
})

export const addUpdateDataFail = error => ({
  type: ADD_UPDATE_DATA_FAIL,
  payload: error,
})

export const clearData = () => ({
  type: CLEAR_DATA,
  payload: "",
})
