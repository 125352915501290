import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import api from "constants/api"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb2"

import avatar from "../../assets/images/users/avatar-1.jpg"
import configJs from "../../config"
import messageConstant from "constants/message"
import { addUpdateData, addUpdateDataMultipleForm } from "store/actions"
import ProfileImageUpdate from "./ProfileImageUpdate"
import BasicUserDetailUpdate from "./BasicUserDetailUpdate"
import ChangePassword from "./ChangePassword"
import { checkImageDimension, isImageJpgOrPng } from "common/commonFunction"
import { isEmpty, set } from "lodash"

const UserProfile = props => {
  //meta title
  document.title = "Profile | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const [userDetails, setUserDetails] = useState({})
  const [isApiCall, setIsApiCall] = useState(0)
  const [updateImage, setUserUpdateImage] = useState("")
  const [updateBasicDetails, setUpdateBasicDetails] = useState("")
  const [updateChangePassword, setUpdateChangePassword] = useState("")

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  useEffect(() => {
    if (isApiCall == 0) {
      const leonAuthUserDetails = localStorage.getItem("leonAuthUserDetails")
      if (!isEmpty(leonAuthUserDetails)) {
        const leonAuthUserDetailsObj = JSON.parse(leonAuthUserDetails)
        setUserDetails(leonAuthUserDetailsObj)
      }
      setIsApiCall(1)
    }
  }, [userDetails])

  const validationProfileSubmit = useFormik({
    enableReinitialize: true,

    initialValues: {
      profile_image: "",
    },
    validationSchema: Yup.object({
      profile_image: Yup.mixed()
        .test("fileFormat", messageConstant.IMAGE_TYPE, value => {
          if (!value) return true // If no image provided, pass validation
          return isImageJpgOrPng(value)
        })
        .test("fileSize", messageConstant.IMAGE_DIMENSION, value => {
          if (!value) return true // If no image provided, pass validation
          if (isImageJpgOrPng(value)) {
            return checkImageDimension(value, 500, 500)
          } else {
            return true
          }
        }),
    }),
    onSubmit: values => {
      setUserUpdateImage("")
      dispatch(
        addUpdateDataMultipleForm(
          values,
          props.router.navigate,
          api.updateImage
        )
      )
    },
  })
  if (
    !isEmpty(data) &&
    !isEmpty(data.userUpdateImage) &&
    isEmpty(updateImage)
  ) {
    setUserUpdateImage(data.userUpdateImage)
    userDetails.profile_image = data.userUpdateImage.profile_image
    localStorage.setItem("leonAuthUserDetails", JSON.stringify(userDetails))
    window.location.reload()
  }

  const validationBasicUserSubmit = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: userDetails.name ? userDetails.name : "",
      email: userDetails.email ? userDetails.email : "",
      mobile: userDetails.mobile ? userDetails.mobile : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(messageConstant.NAME_REQUIRED),
      email: Yup.string()
        .email(messageConstant.EMAIL_INVALID)
        .required(messageConstant.EMAIL_REQUIRED),
      mobile: Yup.string().required(messageConstant.MOBILE_REQUIRED),
    }),
    onSubmit: values => {
      const requestData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
      }
      dispatch(
        addUpdateData(requestData, props.router.navigate, api.editUserDetails)
      )
    },
  })

  if (
    !isEmpty(data) &&
    !isEmpty(data.userUpdate) &&
    isEmpty(updateBasicDetails)
  ) {
    setUpdateBasicDetails(data.userUpdate)
    userDetails.name = data.userUpdate.name
    userDetails.email = data.userUpdate.email
    localStorage.setItem("leonAuthUserDetails", JSON.stringify(userDetails))
    window.location.reload()
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.userUpdate) &&
    isEmpty(updateBasicDetails)
  ) {
    setUpdateBasicDetails(data.userUpdate)
    userDetails.name = data.userUpdate.name
    userDetails.mobile = data.userUpdate.mobile
    localStorage.setItem("leonAuthUserDetails", JSON.stringify(userDetails))
    window.location.reload()
  }

  const [deleteImageData, setDeleteImageData] = useState("")
  const deleteImage = () => {
    setDeleteImageData("")
    const updateData = {
      type: "profile_pic",
    }
    dispatch(addUpdateData(updateData, "", api.removeImage))
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.userDeleteImage) &&
    isEmpty(deleteImageData)
  ) {
    setDeleteImageData(data.userDeleteImage)
    userDetails.profile_image = ""
    localStorage.setItem("leonAuthUserDetails", JSON.stringify(userDetails))
    window.location.reload()
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Profile", path: "/profile" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItem} />
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationProfileSubmit.handleSubmit()
                      return false
                    }}
                  >
                    <ProfileImageUpdate
                      validation={validationProfileSubmit}
                      userDetails={userDetails}
                      deleteImage={deleteImage}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationBasicUserSubmit.handleSubmit()
                      return false
                    }}
                  >
                    <BasicUserDetailUpdate
                      validation={validationBasicUserSubmit}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
