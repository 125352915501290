import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Label,
  Input,
  CardText,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import api from "constants/api"
import Paginations from "components/Common/Pagination"
import Select from "react-select"
const BasicQrDetails = ({ qrId }) => {
  //meta title
  document.title = "Genarate QR Details | " + process.env.REACT_APP_NAME
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: qrId,
  })

  const [isApiCallQr, setIsApiCallQr] = useState(0)
  const [qrcodeDetail, setQrcodeDetail] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isApiCallQr == 0) {
      getListData()
      setIsApiCallQr(1)
    }
  }, [isApiCallQr])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, "", api.generateQrCodeBasicDetails))
  }

  if (!isEmpty(data) && !isEmpty(data.generateQrView) && updateListData) {
    setQrcodeDetail(data.generateQrView)
    setUpdateListData(false)
  }

  return (
    <Row>
      <Col sm={12}>
        {qrcodeDetail ? (
          <Card>
            <CardBody>
              <Row>
                <Col md={3}>
                  <p>
                    Id : <b>{qrcodeDetail.id}</b>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    Title : <b>{qrcodeDetail.title}</b>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    Points : <b>{qrcodeDetail.points}</b>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    No Of Qr : <b>{qrcodeDetail.no_of_qr}</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <p>
                    Scan : <span className="badge bg-light font-size-16">{qrcodeDetail.scan}</span>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    Unscan : <span className="badge bg-light font-size-16">{qrcodeDetail.un_scan}</span>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    Status :{" "}
                    <b>
                      <span
                        className={
                          qrcodeDetail.status == "Published"
                            ? "badge bg-success font-size-12"
                            : qrcodeDetail.status == "Draft"
                              ? "badge bg-secondary font-size-12"
                              : "badge bg-danger font-size-12"
                        }
                      >
                        {qrcodeDetail.status}
                      </span>
                    </b>
                  </p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={12}>
                  <p>
                    Description : <b>{qrcodeDetail.description}</b>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : (
          ""
        )}
      </Col>
    </Row>
  )
}

export default BasicQrDetails
