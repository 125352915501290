import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import configJs from "../../config"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap"
import { isEmpty, set, size } from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import api from "constants/api"
import { clearData, getData } from "store/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { formateDate } from "common/commonFunction"
const CardDetail = ({ userId, batch_id }) => {
  const dispatch = useDispatch()

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  let linkIdAdd = "view/"
  if (userId > 0) {
    linkIdAdd = "transaction/view/"
  }
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const refComp = useRef(null)
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
    user_id: userId,
    page_type: "transaction",
    date_from: startDate,
    date_to: endDate,
    batch_id: batch_id,
  })

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "user.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.user.name ? (
                <div className="row align-items-center flex-nowrap">
                  <div className="col-auto">
                    {!cell.row.original.user.profile_image ? (
                      <>
                        {" "}
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {cell.row.original.user.name.charAt(0)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div>
                          <img
                            className="rounded-circle avatar-xs"
                            src={cell.row.original.user.name}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col ps-0">
                    <Link
                      to={linkIdAdd + cell.row.original.id}
                      className="text-body fw-semibold"
                    >
                      {cell.row.original.user.name}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Qr title",
        accessorKey: "generate_qr_title",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Points",
        accessorKey: "points",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cell => {
          return (
            <>
              <Link
                to={linkIdAdd + cell.row.original.id}
                id="viewDataTooltip"
                className="text-body fw-semibold"
              >
                <i className="bx bx-show"></i>
              </Link>
              <UncontrolledTooltip target="viewDataTooltip">
                View
              </UncontrolledTooltip>
            </>
          )
        },
      },
    ],
    []
  )

  const [isApiCallTransaction, setIsApiCallTransaction] = useState(0)
  const [transactionList, setTransactionList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [totalNoOfScan, setTotalNoOfScan] = useState(0)
  const [totalNoOfPayout, setTotalNoOfPayout] = useState(0)
  const [totalScanPoints, setTotalScanPoints] = useState(0)
  const [totalPayoutPoints, setTotalPayoutPoints] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCallTransaction == 0) {
      getListData()
      setIsApiCallTransaction(1)
    }
  }, [isApiCallTransaction])

  const getListData = () => {
    setUpdateListData(true)
    setTransactionList([])
    setListCount(0)
    setTotalNoOfScan(0)
    setTotalNoOfPayout(0)
    setTotalScanPoints(0)
    setTotalPayoutPoints(0)
    dispatch(clearData())
    dispatch(getData(filterData, "", api.transactionList))
  }

  if (!isEmpty(data) && !isEmpty(data.transactionList) && updateListData) {
    setTransactionList(data.transactionList)
    setTotalNoOfScan(data.total_no_of_scan)
    setTotalNoOfPayout(data.total_no_of_payout)
    setTotalScanPoints(data.total_scan_points)
    setTotalPayoutPoints(data.total_payout_points)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }
  const handleDateChange = e => {
    setStartDate(formateDate(e[0]))
    setEndDate(formateDate(e[1]))
  }
  const handleClear = () => {
    refComp.current.flatpickr.clear()
    setStartDate("")
    setEndDate("")
    filterData.date_from = ""
    filterData.date_to = ""
    filterData.search = ""
    getListData()
  }

  const handleFilter = () => {
    filterData.date_from = startDate;
    filterData.date_to = endDate;
    getListData()
  }
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="mini-stats-wid bg-success">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className={"text-white mb-2"}>No Of Scan</p>
                  <h5 className="mb-0 text-white">{totalNoOfScan}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card className="mini-stats-wid bg-primary">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className={"text-white mb-2"}>No Of Payout</p>
                  <h5 className="mb-0 text-white">{totalNoOfPayout}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card className="mini-stats-wid bg-warning">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className={"text-white mb-2"}>Total Scan Points</p>
                  <h5 className="mb-0 text-white">{totalScanPoints}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card className="mini-stats-wid bg-info">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className={"text-white mb-2"}>Total Payout Points</p>
                  <h5 className="mb-0 text-white">{totalPayoutPoints}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={4}>
                  <div className=" mb-3">
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Select Date Range"
                      onChange={handleDateChange}
                      ref={refComp}
                      options={{
                        mode: "range",
                        dateFormat: "d-m-Y",
                      }}
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="text-sm-end">
                    <button
                      type="button"
                      onClick={handleFilter}
                      className="btn btn-primary w-md"
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      onClick={handleClear}
                      className="ms-2 btn btn-secondary w-md"
                    >
                      Clear
                    </button>
                    &nbsp;&nbsp;
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={transactionList}
                isGlobalFilter={true}
                isAddButton={false}
                isCustomPageSize={true}
                handleClicks=""
                isPagination={true}
                SearchPlaceholder={
                  listCount > 0
                    ? "Search in " + listCount + " records..."
                    : "Search in records..."
                }
                buttonClass="btn btn-primary  waves-effect waves-light mb-2 me-2"
                buttonName="Add Transaction"
                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                theadClass="table-light"
                pagination="pagination"
                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                filterData={filterData}
                getListData={getListData}
                listCount={listCount}
                isApiCall={isApiCallTransaction}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  userId: PropTypes.number,
  batch_id: PropTypes.number,
}

export default CardDetail
