import withRouter from "components/Common/withRouter"
import { isEmpty, set } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import api from "constants/api"
import QRCode from "react-qr-code"
import Select from "react-select"
import Paginations from "components/Common/Pagination"
import { changeDateFormat } from "common/commonFunction"
import BasicQrDetails from "./BsicQrDetail"

const GenarateDetails = props => {
  //meta title
  document.title = "Genarate QR Details | " + process.env.REACT_APP_NAME
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    qr_chunk_id: props.router.params ? props.router.params.chunk_id : "",
    qr_code: "",
    status: "",
    start: configJs.api_parameter.START,
    limit: 100,
    search: "",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "#ced4da",
    }),
    menu: base => ({
      ...base,
      borderRadius: 0.25,
      marginTop: 0,
      borderColor: "#ced4da",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      backgroundColor: "#fff",
      borderColor: "#ced4da",
    }),
  }
  const options = [
    { value: "Unscan", label: "Unscan" },
    { value: "Scan", label: "Scan" },
  ]
  const [isApiCall, setIsApiCall] = useState(0)
  const [qrCodeList, setQrCodeList] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [listCount, setListCount] = useState(0)
  const [col5, setCol5] = useState(false)
  const [filterSearch, setFilterSearch] = useState("")
  const dispatch = useDispatch()
  const t_col5 = () => setCol5(!col5)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setQrCodeList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(
      getData(filterData, props.router.navigate, api.generateQrCodeChuckList)
    )
  }

  if (!isEmpty(data) && !isEmpty(data.qrCodeList) && updateListData) {
    setQrCodeList(data.qrCodeList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  const handleChange = selectedOption => {
    setFilterData({ ...filterData, status: selectedOption.value })
    setFilterSearch(selectedOption)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Genarate QR", path: "/generate-qr" },
    {
      title: "Genarate QR Chunk",
      path: "/generate-qr/view/" + props.router.params.id,
    },
    { title: "Genarate QR Code List", path: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Genarate QR Details"
            breadcrumbItems={breadcrumbItem}
          />
          <BasicQrDetails qrId={props.router.params.id} />

          <Row className="mb-3">
            <Col lg={6}>
              <span>
                <b>{listCount} Qrcode</b>
              </span>
            </Col>
            <Col lg={6} className="text-end">
              <button
                onClick={t_col5}
                className="btn btn-primary ms-3"
                type="button"
              >
                <i className="bx bx-filter-alt align-middle me-2"></i>Filter
              </button>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Collapse isOpen={col5}>
                <Card className="border">
                  <CardBody>
                    <Row>
                      <Col>
                        <Row>
                          <Col lg={4}>
                            <Label>QR Code</Label>
                            <div className="search-box mb-2 me-2">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  className="form-control rounded"
                                  placeholder="QR Code"
                                  value={filterData.qr_code}
                                  onChange={e =>
                                    setFilterData({
                                      ...filterData,
                                      qr_code: e.target.value,
                                    })
                                  }
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="project-status-input">
                                Status
                              </Label>
                              <Select
                                className="select2-selection"
                                styles={customStyles}
                                placeholder="Select Status"
                                value={filterSearch}
                                options={options}
                                onChange={handleChange}
                              ></Select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="auto" className="align-self-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            getListData()
                          }}
                        >
                          Filter
                        </button>
                        <button
                          className="btn btn-secondary ms-2"
                          type="button"
                          onClick={() => {
                            filterData.status = ""
                            filterData.qr_code = ""
                            setFilterSearch("")
                            getListData()
                          }}
                        >
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {!isEmpty(qrCodeList) &&
                qrCodeList.map((item, key) => (
                  <Card key={key}>
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3">
                          <QRCode value={item.qr_code} size={50} />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <Row className="align-items-center">
                            <Col>
                              <p className="mb-0">
                                <b>{item.qr_code}</b>
                              </p>
                            </Col>
                            <Col>
                              <h5 className="mb-2">
                                {item.user ? item.user.name : ""}
                              </h5>
                              {item.scan_at_new && (
                                <p className="mb-0">
                                  <i className="bx bx-calendar me-1"></i>
                                  {changeDateFormat(item.scan_at_new)}
                                </p>
                              )}
                            </Col>
                            <Col>
                              <h5 className="mb-0 d-flex align-items-center">
                                <span
                                  className={
                                    item.status == "Scan"
                                      ? "badge bg-success font-size-12"
                                      : "badge bg-secondary font-size-12"
                                  }
                                >
                                  {item.status}
                                </span>
                                <div className="avatar-sm ms-2">
                                  <span className="avatar-title rounded-circle bg-light text-muted font-size-16">
                                    {item.points}
                                  </span>
                                </div>
                              </h5>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              {isEmpty(qrCodeList) && (
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <h4>No Data Found</h4>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          {listCount > 0 && (
            <Paginations
              perPageData={filterData.limit}
              data={qrCodeList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              listCount={listCount}
              getFilterData={getListData}
              filterData={filterData}
              isShowingPageLength={false}
              paginationDiv="col-12"
              paginationClass="pagination pagination-rounded  mt-2 mb-5"
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(GenarateDetails)
