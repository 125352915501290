const messageConstant = {
  EMAIL_REQUIRED: "Email is required",
  EMAIL_INVALID: "Email is invalid",
  OTP_REQUIRED: "OTP is required",
  OTP_INVALID: "OTP is invalid",
  NAME_REQUIRED: "Name is required",
  NAME_INVALID: "Name is invalid",
  MOBILE_REQUIRED: "Mobile is required",
  MOBILE_INVALID: "Mobile is invalid",
  IMAGE_REQUIRED: "Image is required",
  IMAGE_TYPE: "Image type should be jpg or png",
  IMAGE_DIMENSION: "Image dimensions should be at 500x500 least.",
  DATE_REQUIRED: "Date is required",
  DESIGNATION_REQUIRED: "Designation is required",
  MERCHANT_NAME_REQUIRED: "Merchant Name is required",
  SHORT_DESCRIPTION_REQUIRED: "Short Description is required",
  DESCRIPTION_REQUIRED: "Description is required",
  ADDRESS_REQUIRED: "Address is required",
  COUNTRY_REQUIRED: "Country is required",
  STATE_REQUIRED: "State is required",
  CITY_REQUIRED: "City is required",
  LATITUDE_REQUIRED: "Latitude is required",
  LONGITUDE_REQUIRED: "Longitude is required",
  ZIPCODE_REQUIRED: "Zipcode is required",
  TITLE_REQUIRED: "Title is required",
  TITLE_INVALID: "Title is invalid",
  POINTS_REQUIRED: "Points is required",
  NO_OF_QR_REQUIRED: "No of QR is required",
  TYPE_REQUIRED: "Type is required",
  TIMER_REQUIRED: "Timer is required",
  TERMS_AND_CONDITIONS_REQUIRED: "Terms and Conditions is required",
  POINTS_INVALID: "Points is invalid",
  NO_OF_QR_INVALID: "No of QR is invalid",
  PROFILE_IMAGE_REQUIRED: "Profile Image is required",
  PROFILE_IMAGE_INVALID: "Profile Image is invalid",
  OLD_PASSWORD_REQUIRED: "Old Password is required",
  NEW_PASSWORD_REQUIRED: "New Password is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required",
  CONCAT_NUMBER_REQUIRED: "Contact Number is required",
}
export default messageConstant
