import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { isEmpty, size } from "lodash"
import { changeDateTimeFormat } from "common/commonFunction"

const UPIDetails = ({ userDetail }) => {
  return (
    <React.Fragment>
      <Card>
        <div className="card-header bg-transparent border-bottom ">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0">UPI Details</h5>
          </div>
        </div>
        <CardBody>
          <Row>
            <Col>
              <p className="mb-0">
                UPI id:<b className="ms-1">{userDetail.upi_id}</b>
                {userDetail.upi_id ? (
                  <span className="ms-1">
                    {userDetail.upi_verified == "Yes" ? (
                      <i className="mdi mdi-check-bold text-success"></i>
                    ) : (
                      <i className="mdi mdi-close-thick text-danger"></i>
                    )}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </Col>
            <Col sm="auto">
              <p className="mb-0">
                UPI Name :<b className="ms-1">{userDetail.upi_name}</b>
              </p>
            </Col>
          </Row>
          {userDetail.upi_verified == "Yes" && (
            <Row className="mt-2">
              <Col>
                <p className="mb-0">
                  UPI Verify Date :
                  <b className="ms-1">
                    {changeDateTimeFormat(userDetail.upi_verified_at)}
                  </b>
                </p>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

UPIDetails.propTypes = {
  userDetail: PropTypes.object,
}

export default UPIDetails
