import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { addUpdateData, clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import Text from "common/PageComponent/Text"
import Dropdown from "common/PageComponent/Dropdown"
import labelConstant from "constants/label"
import Mobile from "common/PageComponent/Mobile"
import { useFormik } from "formik"
import * as Yup from "yup"
import DeleteModal from "components/Common/DeleteModal"
import Description from "common/PageComponent/Description"
import messageConstant from "constants/message"

const Setting = props => {
  //meta title
  document.title = "Generate Qrcode | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
  })
  const [isApiCall, setIsApiCall] = useState(0)
  const [settingList, setSettingList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [setting, setSetting] = useState("")
  const [addSetting, setAddSetting] = useState("")
  const [updateSetting, setUpdateSetting] = useState("")

  /**Add Edit Setting*/
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      address_id: "",
      address: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required(messageConstant.ADDRESS_REQUIRED),
    }),
    onSubmit: values => {
      if (values["address_id"]) {
        const updateData = {
          id: values["address_id"],
          key: "Address",
          value: values["address"],
        }
        dispatch(
          addUpdateData(updateData, props.router.navigate, api.settingEdit)
        )
      } else {
        setAddSetting("")
        const newData = {
          key: "Address",
          value: values["address"],
        }
        dispatch(addUpdateData(newData, props.router.navigate, api.settingAdd))
      }
    },
  })
  const validation1 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email_id: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID),
    }),
    onSubmit: values => {
      if (values["email_id"]) {
        const updateData = {
          id: values["email_id"],
          key: "Contact Email",
          value: values["email"],
        }
        dispatch(
          addUpdateData(updateData, props.router.navigate, api.settingEdit)
        )
      } else {
        setAddSetting("")
        const newData = {
          key: "Contact Email",
          value: values["email"],
        }
        dispatch(addUpdateData(newData, props.router.navigate, api.settingAdd))
      }
    },
  })
  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      mobile_id: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required(messageConstant.CONCAT_NUMBER_REQUIRED),
    }),
    onSubmit: values => {
      if (values["mobile_id"]) {
        const updateData = {
          id: values["mobile_id"],
          key: "Contact Number",
          value: values["mobile"],
        }
        dispatch(
          addUpdateData(updateData, props.router.navigate, api.settingEdit)
        )
      } else {
        setAddSetting("")
        const newData = {
          key: "Contact Number",
          value: values["mobile"],
        }
        dispatch(addUpdateData(newData, props.router.navigate, api.settingAdd))
      }
    },
  })
  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setSettingList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.settingList))
  }

  if (!isEmpty(data) && !isEmpty(data.settingList) && updateListData) {
    setSettingList(data.settingList)
    setListCount(data.filter_count)
    data.settingList.map((item, index) => {
      if (item.key == "Address") {
        validation.setFieldValue("address_id", item.id)
        validation.setFieldValue("address", item.value)
      } else if (item.key == "Contact Email") {
        validation1.setFieldValue("email_id", item.id)
        validation1.setFieldValue("email", item.value)
      } else if (item.key == "Contact Number") {
        validation2.setFieldValue("mobile_id", item.id)
        validation2.setFieldValue("mobile", item.value)
      }
    })
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Setting", path: "#" },
  ]

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }
  if (!loading && validation1.isSubmitting) {
    validation1.isSubmitting = false
  }
  if (!loading && validation2.isSubmitting) {
    validation2.isSubmitting = false
  }
  if (!isEmpty(data) && !isEmpty(data.settingUpdate) && isEmpty(addSetting)) {
    setAddSetting(data.settingUpdate)
    getListData()
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.settingUpdate) &&
    isEmpty(updateSetting)
  ) {
    setUpdateSetting(data.settingUpdate)
    getListData()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Setting" breadcrumbItems={breadcrumbItem} />
          <Row>
            <Col xs={6}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <Description
                          validation={validation}
                          name="address"
                          label={labelConstant.ADDRESS}
                          isRequire={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            disabled={validation.isSubmitting ? true : false}
                            className="ms-2 btn btn-primary  save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation1.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <Text
                          validation={validation1}
                          name="email"
                          label={labelConstant.EMAIL}
                          isRequire={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            disabled={validation1.isSubmitting ? true : false}
                            className="ms-2 btn btn-primary  save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation2.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <Text
                          validation={validation2}
                          name="mobile"
                          label={labelConstant.MOBILE}
                          isRequire={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            disabled={validation2.isSubmitting ? true : false}
                            className="ms-2 btn btn-primary  save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Setting)
