import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import CardBanner from "./CardList"
const Banner = props => {
  document.title = "Banner Detail| " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params ? props.router.params.id : "",
  })
  const [banner, setBanner] = useState("")
  const [isApiCall, setIsApiCall] = useState(0)
  const [bannerView, setBannerView] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [changeStatusBanner, setChangeStatusBanner] = useState("")
  const [deleteBanner, setDeleteBanner] = useState("")

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.bannerDetails))
  }

  if (!isEmpty(data) && !isEmpty(data.bannerView) && updateListData) {
    setBannerView(data.bannerView)
    setUpdateListData(false)
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Banner", path: "/banner" },
    { title: "Banner Details", path: "#" },
  ]
  /**Change Status */
  const handleStatusChange = (status, id) => {
    if (status == "Active") {
      status = "Inactive"
    } else {
      status = "Active"
    }
    const updateData = {
      status: status,
      id: id,
    }
    dispatch(
      addUpdateData(updateData, props.router.navigate, api.bannerChangeStatus)
    )
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.bannerChangeStatus) &&
    isEmpty(changeStatusBanner)
  ) {
    setChangeStatusBanner(data.bannerChangeStatus)
    window.location.reload()
  }

  //delete Banner
  const [deleteModal, setDeleteModal] = useState(false)
  const onClickDelete = banner => {
    setBanner(banner)
    setDeleteModal(true)
  }
  const handleDeleteBanner = () => {
    if (banner && banner.id) {
      const deleteData = {
        id: banner.id,
      }
      dispatch(
        addUpdateData(deleteData, props.router.navigate, api.bannerDelete)
      )
    }
  }

  if (!isEmpty(data) && !isEmpty(data.bannerDestroy) && isEmpty(deleteBanner)) {
    setDeleteModal(false)
    setDeleteBanner(data.bannerDestroy)
    window.location.reload()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Banner Details"
            breadcrumbItems={breadcrumbItem}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteBanner}
            modalTitle="Banner"
            onCloseClick={() => setDeleteModal(false)}
          />
          <Row>
            <Col xl="6">
              <CardBanner
                singleBanner={bannerView}
                onClickDelete={onClickDelete}
                handleStatusChange={handleStatusChange}
                listPage="No"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Banner)
