import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup
} from "reactstrap"
import { Link } from "react-router-dom"
import api from "constants/api"
import { clearData, getData } from "store/actions"
import { isEmpty, set } from "lodash"
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import Switch from "react-switch";

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | " + process.env.REACT_APP_NAME
  const [isApiCall, setIsApiCall] = useState(0)
  const [dashboardData, setDashboardData] = useState([])
  const [distributorCount, setDistributorCount] = useState(0)
  const [retailerCount, setRetailerCount] = useState(0)
  const [electricianCount, setElectricianCount] = useState(0)
  const [payoutRequestNumber, setPayoutRequestNumber] = useState(0)
  const [switchChart, setSwitchChart] = useState(true);

  const [date, setDate] = useState(new Date())
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [series, setSeries] = useState()
  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#34c38f", "#f46a6a"],
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: "Points",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
  })
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall === 0) {
      getListData('', '')
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = (Year, Month) => {
    setDashboardData([])
    setDistributorCount(0)
    setRetailerCount(0)
    setElectricianCount(0)
    setPayoutRequestNumber(0)
    setUpdateListData(true)
    dispatch(clearData())
    const filterData = {
      year: Year,
      month: Month,
    }
    dispatch(getData(filterData, '', api.dashboard))
  }

  if (!isEmpty(data) && !isEmpty(data.counts) && updateListData) {
    setDashboardData(data.array)
    setDistributorCount(data.counts.distributor_count)
    setRetailerCount(data.counts.retailer_count)
    setElectricianCount(data.counts.electrician_count)
    setPayoutRequestNumber(data.counts.no_payout_request)
    let selectedDatePayoutArray = data.array.map((item) => item.total_payout_amount)
    let selectedDateScanArray = data.array.map((item) => item.total_scan_amount)
    let selectedDatePayoutNoArray = data.array.map((item) => item.total_no_of_payouts)
    let selectedDateScanNoArray = data.array.map((item) => item.total_no_of_scan)

    const selectedDateArray = data.array.map((item) => item.date)

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        categories: selectedDateArray,
      },
    }))

    if (switchChart) {
      setSeries([
        {
          name: "Payouts request",
          data: selectedDatePayoutArray,
          color: "#f46a6a"
        },
        {
          name: "Scanning",
          data: selectedDateScanArray,
          color: "#34c38f",
        },
      ])
    } else {
      setSeries([
        {
          name: "Payouts request",
          data: selectedDatePayoutNoArray,
          color: "#f46a6a"
        },
        {
          name: "Scanning",
          data: selectedDateScanNoArray,
          color: "#34c38f",
        },
      ])
    }
    setUpdateListData(false)
  }

  const optionsDate = {
    dateFormat: "Y-m",
    plugins: [
      new monthSelectPlugin({
        shorthand: true, // Whether to display months as shorthand (e.g. "Jan", "Feb", etc.)
        dateFormat: "Y-m", // format string to show month and year
        altFormat: "F Y", // format string to parse datetime in input
      }),
    ],
    static: true, // optional: adds a static element, useful when using inline: true
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Distributor
                      </p>
                      <h4 className="mb-0">{distributorCount}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i
                          className={
                            "bx bx-user font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Retailer
                      </p>
                      <h4 className="mb-0">{retailerCount}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i
                          className={
                            "bx bx-user font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Electrician
                      </p>
                      <h4 className="mb-0">{electricianCount}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i
                          className={
                            "bx bx-user font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">
                        Payout Request
                      </p>
                      <h4 className="mb-0">{payoutRequestNumber}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i
                          className={
                            "bx bx-user font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  value={date}
                  options={optionsDate}
                  onChange={([selectedDate]) => {
                    setDate(selectedDate);
                    getListData(selectedDate.getFullYear(), selectedDate.getMonth() + 1);
                  }}
                />
              </InputGroup>
            </Col>
            <Col lg="4" className="mb-3 d-flex align-items-center">
              <span className="p-2">Transaction</span>
              <Switch
                uncheckedIcon={
                  <div style={{ padding: '2px 6px', color: 'white', fontSize: '12px' }}></div>
                }
                checkedIcon={
                  <div style={{ padding: '2px 6px', color: 'white', fontSize: '12px' }}></div>
                }
                className="me-1 mb-sm-8 mb-2"
                onColor="#626ed4"
                offColor="#d3d3d3"
                onChange={() => {
                  setSwitchChart(!switchChart);
                  getListData(date.getFullYear(), date.getMonth() + 1);
                }}
                checked={switchChart}
              />
              <span>Points</span>
            </Col>
          </Row>
          {options && series && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="line-chart" className="apex-charts">
                      <ReactApexChart options={options} series={series} type="line" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Dashboard)
