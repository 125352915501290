import PropTypes from "prop-types"
import React from "react"
const SubmitButton = ({ label }) => {
  return (
    <div className="mb-3 text-end">
      <button type="submit" className="btn btn-primary">
        {label}
      </button>
    </div>
  )
}

SubmitButton.propTypes = {
  label: PropTypes.string,
}

export default SubmitButton
