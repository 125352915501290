import { isEmpty, set } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import configJs from "../../config"
import { addUpdateData, clearData, getData } from "store/actions"
// flatpickr
import api from "constants/api"
import "flatpickr/dist/themes/material_blue.css"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import Text from "common/PageComponent/Text"
import Dropdown from "common/PageComponent/Dropdown"
import labelConstant from "constants/label"
import Mobile from "common/PageComponent/Mobile"
import { useFormik } from "formik"
import * as Yup from "yup"
import DeleteModal from "components/Common/DeleteModal"
import Description from "common/PageComponent/Description"
import messageConstant from "constants/message"

const StoreLocator = props => {
  //meta title
  document.title = "Store Locator | " + process.env.REACT_APP_NAME
  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const { loading } = useSelector(state => ({
    loading: state.AppMaster.loading,
  }))
  const [filterData, setFilterData] = useState({
    start: configJs.api_parameter.START,
    limit: configJs.api_parameter.LIMIT,
    search: "",
    sort_by: configJs.api_parameter.SORTBY,
    sort_order: configJs.api_parameter.SORTORDER,
  })

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "user.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.name ? (
                <div className="row align-items-center flex-nowrap">
                  <div className="col-auto">
                    {!cell.row.original.profile_image ? (
                      <>
                        {" "}
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {cell.row.original.name.charAt(0)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div>
                          <img
                            className="rounded-circle avatar-xs"
                            src={cell.row.original.profile_image}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col">
                    <Link
                      to={`view/${cell.row.original.id}`}
                      className="text-secondary"
                    >
                      {cell.row.original.name}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.row.original.mobile ? (
                <Link
                  to={`view/${cell.row.original.id}`}
                  className="text-secondary"
                >
                  {cell.row.original.mobile}
                </Link>
              ) : (
                ""
              )}
            </>
          )
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Latitude",
        accessorKey: "latitude",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Longitude",
        accessorKey: "longitude",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <Link
              to="#"
              onClick={() =>
                handleStatusChange(
                  cell.row.original.status,
                  cell.row.original.id
                )
              }
              className={
                cell.row.original.status == "Active"
                  ? "badge bg-success  ms-1"
                  : "badge bg-danger  me-1"
              }
            >
              {cell.row.original.status}
            </Link>
          )
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cell => {
          return (
            <>
              <Link
                to={`view/${cell.row.original.id}`}
                className="text-secondary"
              >
                <i className="mdi mdi-eye font-size-18 me-1"></i>
              </Link>
              <Link
                to="#"
                className="text-secondary"
                onClick={() => {
                  const editData = cell.row.original
                  handleEditClick(editData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                <UncontrolledTooltip placement="top" target="editTooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                onClick={() => onClickDelete(cell.row.original)}
                className="text-muted"
              >
                <i className="mdi mdi-delete font-size-18"></i>
              </Link>
            </>
          )
        },
      },
    ],
    []
  )

  const [isApiCall, setIsApiCall] = useState(0)
  const [storeLocatorList, setStoreLocatorList] = useState([])
  const [listCount, setListCount] = useState(0)
  const [updateListData, setUpdateListData] = useState(false)
  const [storeLocator, setStoreLocator] = useState("")
  const [changeStatusStoreLocator, setChangeStatusStoreLocator] = useState("")
  const [deleteStoreLocator, setDeleteStoreLocator] = useState("")
  const [editStoreLocator, setEditStoreLocator] = useState("")
  const [addStoreLocator, setAddStoreLocator] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [optionGroupState, setOptionGroupState] = useState([])
  const [optionGroupCity, setOptionGroupCity] = useState([])
  const [selectedCity, setSelectedCity] = useState("")
  const [selectedState, setSelectedState] = useState("")
  /**Add Edit StoreLocator*/
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (storeLocator && storeLocator.id) || "",
      name: (storeLocator && storeLocator.name) || "",
      email: (storeLocator && storeLocator.email) || "",
      mobile: (storeLocator && storeLocator.mobile) || "",
      latitude: (storeLocator && storeLocator.latitude) || "",
      longitude: (storeLocator && storeLocator.longitude) || "",
      zip_code: (storeLocator && storeLocator.zip_code) || "",
      state_id: (storeLocator && storeLocator.state_id) || "",
      city_id: (storeLocator && storeLocator.city_id) || "",
      address: (storeLocator && storeLocator.address) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.NAME_REQUIRED)
        .max(250, messageConstant.NAME_INVALID)
        .matches(/^[a-zA-Z0-9\s]+$/, messageConstant.NAME_INVALID),
      email: Yup.string().email(messageConstant.EMAIL_INVALID),
      mobile: Yup.string()
        .required(messageConstant.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID),
      latitude: Yup.string().required(messageConstant.LATITUDE_REQUIRED),
      longitude: Yup.string().required(messageConstant.LONGITUDE_REQUIRED),
      zip_code: Yup.string().required(messageConstant.ZIPCODE_REQUIRED),
      state_id: Yup.string().required(messageConstant.STATE_REQUIRED),
      city_id: Yup.string().required(messageConstant.CITY_REQUIRED),
      address: Yup.string().required(messageConstant.ADDRESS_REQUIRED),
    }),
    onSubmit: values => {
      if (isEdit) {
        setEditStoreLocator("")
        const updateData = {
          id: storeLocator.id,
          name: values["name"],
          email: values["email"],
          mobile: values["mobile"],
          latitude: values["latitude"],
          longitude: values["longitude"],
          zip_code: values["zip_code"],
          state_id: values["state_id"],
          city_id: values["city_id"],
          address: values["address"],
        }
        dispatch(
          addUpdateData(updateData, props.router.navigate, api.storeLocatorEdit)
        )
      } else {
        setAddStoreLocator("")
        const newData = {
          name: values["name"],
          email: values["email"],
          mobile: values["mobile"],
          latitude: values["latitude"],
          longitude: values["longitude"],
          zip_code: values["zip_code"],
          state_id: values["state_id"],
          city_id: values["city_id"],
          address: values["address"],
        }
        dispatch(
          addUpdateData(newData, props.router.navigate, api.storeLocatorAdd)
        )
      }
    },
  })
  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      getStateList()
      setIsApiCall(1)
    }
  }, [isApiCall])
  useEffect(() => {
    if (isApiCall == 1 && validation.values.state_id) {
      getCityList()
    }
  }, [validation.values.state_id])

  const getListData = () => {
    setStoreLocatorList([])
    setListCount(0)
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.storeLocatorList))
  }
  const getStateList = () => {
    setStateList([])
    setOptionGroupState([])
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.stateList))
  }
  const getCityList = () => {
    setCityList([])
    setOptionGroupCity([])
    dispatch(clearData())
    const filterData = {
      state_id: validation.values.state_id,
    }
    dispatch(getData(filterData, props.router.navigate, api.cityList))
  }
  if (!isEmpty(data) && !isEmpty(data.storeList) && updateListData) {
    setStoreLocatorList(data.storeList)
    setListCount(data.filter_count)
    setUpdateListData(false)
  }

  if (!isEmpty(data) && !isEmpty(data.stateList) && isEmpty(optionGroupState)) {
    setStateList(data.stateList)
    data.stateList.map((item, index) => {
      optionGroupState.push({ value: item.id, label: item.name })
    })
  }

  if (!isEmpty(data) && !isEmpty(data.cityList) && isEmpty(optionGroupCity)) {
    setStateList(data.cityList)
    data.cityList.map((item, index) => {
      optionGroupCity.push({ value: item.id, label: item.name })
    })
  }
  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Store Locator", path: "/store-locator" },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const handleClick = () => {
    validation.resetForm()
    setStoreLocator()
    setSelectedCity("")
    setSelectedState("")
    setIsEdit(false)
    toggle()
  }
  const handleEditClick = arg => {
    const storeLocator = arg
    setStoreLocator({
      id: storeLocator.id,
      name: storeLocator.name,
      mobile: storeLocator.mobile,
      email: storeLocator.email,
      latitude: storeLocator.latitude,
      longitude: storeLocator.longitude,
      zip_code: storeLocator.zip_code,
      state_id: storeLocator.state_id,
      city_id: storeLocator.city_id,
      address: storeLocator.address,
    })
    if (!isEmpty(storeLocator.city)) {
      setSelectedCity({
        value: storeLocator.city.id,
        label: storeLocator.city.name,
      })
    }
    if (!isEmpty(storeLocator.state)) {
      setSelectedState({
        value: storeLocator.state.id,
        label: storeLocator.state.name,
      })
    }
    setIsEdit(true)
    toggle()
  }

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false
  }
  if (!isEmpty(data) && !isEmpty(data.storeAdd) && isEmpty(addStoreLocator)) {
    setAddStoreLocator(data.storeAdd)
    validation.resetForm()
    toggle()
    getListData()
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.storeUpdate) &&
    isEmpty(editStoreLocator)
  ) {
    setEditStoreLocator(data.storeUpdate)
    validation.resetForm()
    setIsEdit(false)
    toggle()
    getListData()
  }

  /**Change Status */
  const handleStatusChange = (status, id) => {
    setChangeStatusStoreLocator("")
    if (status == "Active") {
      status = "Inactive"
    } else {
      status = "Active"
    }
    const updateData = {
      status: status,
      id: id,
    }
    dispatch(
      addUpdateData(
        updateData,
        props.router.navigate,
        api.storeLocatorChangeStatus
      )
    )
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.storeChangeStatus) &&
    isEmpty(changeStatusStoreLocator)
  ) {
    setChangeStatusStoreLocator(data.storeChangeStatus)
    getListData()
  }

  //delete Store Locator
  const [deleteModal, setDeleteModal] = useState(false)
  const onClickDelete = store => {
    setStoreLocator(store)
    setDeleteModal(true)
  }
  const handleDeleteBanner = () => {
    setDeleteStoreLocator("")
    if (storeLocator && storeLocator.id) {
      const deleteData = {
        id: storeLocator.id,
      }
      dispatch(
        addUpdateData(deleteData, props.router.navigate, api.storeLocatorDelete)
      )
    }
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.storeDestroy) &&
    isEmpty(deleteStoreLocator)
  ) {
    setDeleteModal(false)
    setDeleteStoreLocator(data.storeDestroy)
    getListData()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Store Locator" breadcrumbItems={breadcrumbItem} />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteBanner}
            modalTitle="Store Locator"
            onCloseClick={() => setDeleteModal(false)}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={storeLocatorList || []}
                    isGlobalFilter={true}
                    isAddButton={true}
                    isCustomPageSize={true}
                    handleClicks={handleClick}
                    isPagination={true}
                    SearchPlaceholder={
                      listCount > 0
                        ? "Search in " + listCount + " records..."
                        : "Search in records..."
                    }
                    buttonClass="btn btn-primary  waves-effect waves-light mb-2 me-2"
                    buttonName="Add Store Locator"
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    filterData={filterData}
                    getListData={getListData}
                    listCount={listCount}
                    isApiCall={isApiCall}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Store Locator " : "Add Store Locator"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="name"
                  label={labelConstant.NAME}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="email"
                  label={labelConstant.EMAIL}
                  isRequire={false}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="mobile"
                  label={labelConstant.MOBILE}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Description
                  validation={validation}
                  name="address"
                  label={labelConstant.ADDRESS}
                  row={3}
                  isRequire={true}
                />
              </Col>

              <Col lg={6}>
                <Text
                  validation={validation}
                  name="latitude"
                  label={labelConstant.LATITUDE}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="longitude"
                  label={labelConstant.LONGITUDE}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Text
                  validation={validation}
                  name="zip_code"
                  label={labelConstant.ZIPCODE}
                  isRequire={true}
                />
              </Col>
              <Col lg={6}>
                <Dropdown
                  validation={validation}
                  name="state_id"
                  label={labelConstant.STATE}
                  optionGroup={optionGroupState}
                  isRequire={true}
                  selectedDropdown={selectedState}
                  setSelectedDropdown={setSelectedState}
                />
              </Col>
              <Col lg={6}>
                <Dropdown
                  validation={validation}
                  name="city_id"
                  label={labelConstant.CITY}
                  optionGroup={optionGroupCity}
                  isRequire={true}
                  selectedDropdown={selectedCity}
                  setSelectedDropdown={setSelectedCity}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    onClick={() => toggle()}
                    type="button"
                    className="btn btn-outline-danger  save-user"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={validation.isSubmitting ? true : false}
                    className="ms-2 btn btn-primary  save-user"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(StoreLocator)
