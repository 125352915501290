import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"

const Paginations = ({
  perPageData,
  data,
  currentPage,
  setCurrentPage,
  listCount,
  getFilterData,
  filterData,
  isShowingPageLength,
  paginationDiv,
  paginationClass,
}) => {
  const handleClick = e => {
    setCurrentPage(Number(e.target.id))
    let start = Number(e.target.id - 1)
    filterData.start = filterData.limit * start
    getFilterData()
  }

  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(listCount / perPageData); i++) {
    pageNumbers.push(i)
  }
  const newPageNumbers = []
  if (pageNumbers.length >= 10) {
    let totalPages = Math.ceil(listCount / perPageData)
    let startPage = currentPage - 5 > 0 ? currentPage - 5 : 1
    let endPage = currentPage + 5 <= totalPages ? currentPage + 5 : totalPages
    for (let i = startPage; i <= endPage; i++) {
      newPageNumbers.push(i)
    }
  }

  const handleprevPage = () => {
    let prevPage = currentPage - 1
    setCurrentPage(prevPage)
    let start = prevPage
    filterData.start = filterData.limit * start
    getFilterData()
  }
  const handlenextPage = event => {
    event.preventDefault()
    let nextPage = currentPage + 1
    setCurrentPage(nextPage)
    let start = nextPage
    filterData.start = filterData.limit * start
    getFilterData()
  }

  useEffect(() => {
    if (pageNumbers.length && pageNumbers.length < currentPage) {
      setCurrentPage(pageNumbers.length)
    }
  }, [pageNumbers.length, currentPage, setCurrentPage])

  return (
    <React.Fragment>
      <Row className="justify-content-between align-items-center">
        {isShowingPageLength && (
          <Col sm={12} md={5}>
            <div className="text-muted dataTables_info">
              Showing {perPageData} of {data?.length} entries
            </div>
          </Col>
        )}
        <div className={paginationDiv}>
          <ul className={paginationClass}>
            <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
              <Link
                className="page-link"
                to="#"
                onClick={() => handleprevPage()}
              >
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>
            {/* {(pageNumbers || []).map((item, index) => (
                            <li className={currentPage === item ? "page-item active " : "page-item"} key={index}>
                                <Link className="page-link" to="#" id={item} onClick={(e) => handleClick(e)}>
                                    {item}
                                </Link>
                            </li>
                        ))} */}
            {pageNumbers.length < 10 &&
              pageNumbers.map((item, index) => (
                <li
                  className={
                    currentPage === item ? "page-item active " : "page-item"
                  }
                  key={index}
                >
                  <Link
                    className="page-link"
                    to="#"
                    id={item}
                    onClick={e => handleClick(e)}
                  >
                    {item}
                  </Link>
                </li>
              ))}
            {pageNumbers.length >= 10 &&
              newPageNumbers.map((item, index) => (
                <li
                  className={
                    currentPage === item ? "page-item active " : "page-item"
                  }
                  key={index}
                >
                  <Link
                    className="page-link"
                    to="#"
                    id={item}
                    onClick={e => handleClick(e)}
                  >
                    {item}
                  </Link>
                </li>
              ))}
            <li className={`page-item ${currentPage > 1 ? "disabled" : ""}`}>
              <Link
                className="page-link"
                to="#"
                onClick={e => handlenextPage(e)}
              >
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    </React.Fragment>
  )
}

export default Paginations
