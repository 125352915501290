import Breadcrumbs from "components/Common/Breadcrumb2"
import withRouter from "components/Common/withRouter"
import React from "react"
import { Container } from "reactstrap"
import AddEditForm from "./AddEditForm"
const Banner = props => {
  document.title = "Banner | " + process.env.REACT_APP_NAME

  const navigate = props.router.navigate
  const parameter = props.router.params

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Banner", path: "/banner" },
    { title: "Banner Add", path: "/banner/add" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Banner" breadcrumbItems={breadcrumbItem} />

          <AddEditForm
            bannerData=""
            history={navigate}
            parameter={parameter}
            getListData=""
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Banner)
