import {
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  ADD_UPDATE_DATA_SUCCESS,
  ADD_UPDATE_DATA_FAIL,
  GET_DATA,
  CLEAR_DATA,
  ADD_UPDATE_DATA,
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  listCount: 0,
  success: "",
  error: "",
  loading: false,
  images: "",
  forgetSuccessMsg: null,
  forgetError: null,
}

const AppMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_DATA:
      return {
        ...state,
        loading: true,
        data: action.payload.data,
      }
    case GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_UPDATE_DATA:
      return {
        ...state,
        loading: true,
        data: action.payload.data,
      }
    case ADD_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case ADD_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CLEAR_DATA:
      return { ...state, data: null, error: null }

    default:
      return state
  }
}

export default AppMaster
