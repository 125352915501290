import "flatpickr/dist/themes/material_blue.css"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import QRCode from "react-qr-code"
import { changeDateTimeFormat } from "common/commonFunction"
import BankTransactionDetails from "./BankTransactionDetails"

const PayoutDetails = ({ transactionView, onClickReInitiate }) => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <div className="col">
                  <Row>
                    <Col md="">
                      <h5>
                        {" "}
                        {transactionView.user
                          ? transactionView.user.name
                          : ""}{" "}
                      </h5>
                    </Col>
                    <Col md="auto">
                      <i className="fas fa-calendar-alt me-1"></i>
                      <strong>
                        {transactionView.created_date
                          ? changeDateTimeFormat(transactionView.created_date)
                          : ""}
                      </strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Points:{" "}
                      <span className="badge bg-danger fs-5">
                        {transactionView.points}
                      </span>
                      <span
                        className={
                          transactionView.pay_out_status == "Completed"
                            ? "fs-6 badge bg-success ms-2"
                            : "fs-6 badge bg-danger ms-2"
                        }
                      >
                        {transactionView.pay_out_status}
                      </span>
                    </Col>
                    <Col md="auto">
                      Type:{" "}
                      <strong>
                        {" "}
                        {transactionView.type}
                        {transactionView.transaction_type == "Failed" ? (
                          <button
                            className="btn btn-sm btn-primary ms-2"
                            onClick={onClickReInitiate}
                          >
                            ReInitiate
                          </button>
                        ) : (
                          ""
                        )}
                      </strong>
                    </Col>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <BankTransactionDetails transactionView={transactionView} />
    </>
  )
}

PayoutDetails.propTypes = {
  transactionView: PropTypes.object,
  handleReInitiatePayment: PropTypes.func,
}

export default PayoutDetails
