import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import QRCode from "react-qr-code"
import { changeDateTimeFormat } from "common/commonFunction"
import ScanDetails from "./ScanDetails"
import PayoutDetails from "./PayoutDetails"

const Transaction = props => {
  document.title = "Transaction | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params ? props.router.params.transaction_id : "",
  })
  const [isApiCall, setIsApiCall] = useState(0)
  const [transactionView, setTransactionView] = useState("")
  const [updateListData, setUpdateListData] = useState(false)

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.transactionDetails))
  }

  if (!isEmpty(data) && !isEmpty(data.transactionView) && updateListData) {
    setTransactionView(data.transactionView)
    setUpdateListData(false)
  }

  let breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Transaction", path: "/transaction" },
    { title: "Transaction Details", path: "#" },
  ]

  const [batchTransferStore, setBatchTransferStore] = useState()
  const [reInitiateModal, setReInitiateModal] = useState(false)
  const onClickReInitiate = () => {
    setReInitiateModal(true)
  }
  const handleDeleteReInitiate = () => {
    const requestData = {
      transaction_ids: transactionView.id,
      type: "ReInitiate",
    }
    dispatch(clearData())
    dispatch(
      getData(requestData, props.router.navigate, api.batchTransferStore)
    )
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.batchTransferStore) &&
    isEmpty(batchTransferStore)
  ) {
    setReInitiateModal(false)
    setBatchTransferStore(data.batchTransferStore)
    props.router.navigate(
      "/payout-transaction/view/" + data.batchTransferStore.id
    )
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Transaction" breadcrumbItems={breadcrumbItem} />
        <DeleteModal
          show={reInitiateModal}
          onDeleteClick={handleDeleteReInitiate}
          modalTitle="Payout Transaction"
          onCloseClick={() => setReInitiateModal(false)}
          modalBody="Are you sure you want to re-initiate this transaction?"
          modalButtonLabel="Yes, Re-Initiate"
        />
        {transactionView.type == "Payout" ? (
          <PayoutDetails
            transactionView={transactionView}
            onClickReInitiate={onClickReInitiate}
          />
        ) : (
          <ScanDetails transactionView={transactionView} />
        )}
      </Container>
    </div>
  )
}

export default withRouter(Transaction)
