import withRouter from "components/Common/withRouter"
import { isEmpty, isNull } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import api from "constants/api"
import { useDispatch, useSelector } from "react-redux"
import { addUpdateData, clearData, getData } from "store/actions"
import configJs from "../../config"
import { changeDateTimeFormat } from "common/commonFunction"

const Inquiry = props => {
  document.title = "Inquiry | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))
  const [filterData, setFilterData] = useState({
    id: props.router.params ? props.router.params.id : "",
  })
  const [isApiCall, setIsApiCall] = useState(0)
  const [inquiryView, setInquiryView] = useState("")
  const [updateListData, setUpdateListData] = useState(false)
  const [changeStatus, setChangeStatus] = useState("")

  useEffect(() => {
    if (isApiCall == 0) {
      getListData()
      setIsApiCall(1)
    }
  }, [isApiCall])

  const getListData = () => {
    setUpdateListData(true)
    dispatch(clearData())
    dispatch(getData(filterData, props.router.navigate, api.inquiryDetails))
  }

  /**Change Status */
  const handleStatusChange = (status, id) => {
    setChangeStatus("")
    const updateData = {
      read: status,
      id: id,
    }
    dispatch(
      addUpdateData(updateData, props.router.navigate, api.inquiryChangeStatus)
    )
  }

  if (!isEmpty(data) && !isEmpty(data.inquiryView) && updateListData) {
    setInquiryView(data.inquiryView)
    setUpdateListData(false)
    if (
      isNull(data.inquiryView.read) ||
      (data.inquiryView.read == "No" && isEmpty(changeStatus))
    ) {
      handleStatusChange("Yes", data.inquiryView.id)
    }
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Inquiry", path: "/inquiry" },
    { title: "Inquiry Details", path: "#" },
  ]

  if (
    !isEmpty(data) &&
    !isEmpty(data.inquiryChangeStatus) &&
    isEmpty(changeStatus)
  ) {
    setChangeStatus(data.inquiryChangeStatus)
    getListData()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Inquiry" breadcrumbItems={breadcrumbItem} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      {!isEmpty(inquiryView.user) &&
                      !isEmpty(inquiryView.user.profile_image) ? (
                        <>
                          <img
                            className="avatar-md rounded-circle img-thumbnail"
                            src={inquiryView.user.profile_image}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          {inquiryView.user && inquiryView.user.name ? (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle bg-primary fs-1">
                                {inquiryView.user.name.charAt(0)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <Row>
                        <Col md={9}>
                          <h5>{inquiryView.name}</h5>
                        </Col>
                        <Col md={3}>
                          {inquiryView.read == "Yes" ? (
                            <div className="text-end">
                              <button
                                className="btn btn-sm btn-dark"
                                onClick={() =>
                                  handleStatusChange("No", inquiryView.id)
                                }
                              >
                                Mark as Unread
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <p className="mb-1">
                            <i className="fas fa-phone-alt me-1"></i>
                            <b>{inquiryView.mobile}</b>
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <i className="fas fa-envelope me-1"></i>
                            <b>{inquiryView.email}</b>
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className="mb-1">
                            <span className="me-1">Company Name :</span>
                            <b>
                              {inquiryView.company_name
                                ? inquiryView.company_name
                                : ""}
                            </b>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="d-flex ">
                          <span className="me-1 text-nowrap">Inquiry :</span>
                          <p className="mb-1">
                            <b>{inquiryView.inquiry}</b>
                          </p>
                        </Col>
                        {inquiryView.created_date ? (
                          <Col md={4}>
                            <p className="mb-1">
                              <span className="me-1">Date :</span>
                              <b>
                                {changeDateTimeFormat(inquiryView.created_date)}
                              </b>
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Inquiry)
