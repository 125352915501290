import React from "react"
import { Navigate } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
// User
import User from "../pages/User/index"
import UserDetails from "../pages/User/Details"
// generate-qr
import GenerateQr from "../pages/GenerateQr/index"
import GenerateDetails from "../pages/GenerateQr/Details"
import QRDetails from "../pages/GenerateQr/QrDetails"
// StoreLocator
import StoreLocator from "../pages/StoreLocator/index"
import StoreLocatorDetails from "../pages/StoreLocator/Details"

// PointRedeem
import PointRedeem from "../pages/Transaction/index"
import PointRedeemDetails from "../pages/Transaction/Details"
// Banner
import Banner from "../pages/Banner/Index"
import BannerDetails from "../pages/Banner/Details"
import BannerAdd from "../pages/Banner/Add"
import BannerEdit from "../pages/Banner/Edit"
import ChangePassword from "pages/Authentication/ChangePassword"
import Setting from "pages/Setting/Index"
import ContactUs from "pages/ContactUs/Index"
import ContactUsDetails from "pages/ContactUs/Details"
import PayoutRequest from "pages/Payout/Request"
import PayoutRejected from "pages/Payout/Rejected"
import PayoutTransaction from "pages/Payout/Transaction"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/user", component: <User /> },
  { path: "/user/view/:id", component: <UserDetails /> },
  {
    path: "/user/view/:id/transaction/view/:transaction_id",
    component: <PointRedeemDetails />,
  },
  { path: "/generate-qr", component: <GenerateQr /> },
  { path: "/generate-qr/view/:id", component: <GenerateDetails /> },
  {
    path: "/generate-qr/view/:id/generate-qr-chunk/:chunk_id",
    component: <QRDetails />,
  },
  { path: "/store-locator", component: <StoreLocator /> },
  { path: "/store-locator/view/:id", component: <StoreLocatorDetails /> },
  { path: "/transaction", component: <PointRedeem /> },
  {
    path: "/transaction/view/:transaction_id",
    component: <PointRedeemDetails />,
  },
  { path: "/payout-request", component: <PayoutRequest /> },
  {
    path: "/payout-request/view/:transaction_id",
    component: <PointRedeemDetails />,
  },
  { path: "/payout-rejected", component: <PayoutRejected /> },
  {
    path: "/payout-rejected/view/:transaction_id",
    component: <PointRedeemDetails />,
  },
  { path: "/payout-transaction", component: <PayoutTransaction /> },
  {
    path: "/payout-transaction/view/:batch_id",
    component: <PointRedeem />,
  },
  {
    path: "/payout-transaction/view/:batch_id/view/:transaction_id",
    component: <PointRedeemDetails />,
  },
  { path: "/profile", component: <UserProfile /> },
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/banner", component: <Banner /> },
  { path: "/banner/view/:id", component: <BannerDetails /> },
  { path: "/banner/add", component: <BannerAdd /> },
  { path: "/banner/edit/:id", component: <BannerEdit /> },
  { path: "/setting", component: <Setting /> },
  { path: "/inquiry", component: <ContactUs /> },
  { path: "/inquiry/view/:id", component: <ContactUsDetails /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]
const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
]
export { authProtectedRoutes, publicRoutes }
