import PropTypes from "prop-types"
import { FormFeedback, Input, Label } from "reactstrap"
import React, { useState } from "react"
import Select from "react-select"
import labelConstant from "constants/label"
const Dropdown = ({
  validation,
  name,
  label,
  optionGroup,
  isRequire,
  selectedDropdown,
  setSelectedDropdown,
}) => {
  const handleDropdown = selectedDropdown => {
    setSelectedDropdown(selectedDropdown)
    validation.setFieldValue(name, selectedDropdown.value)
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "#ced4da",
    }),
    menu: base => ({
      ...base,
      borderRadius: 0.25,
      marginTop: 0,
      borderColor: "#ced4da",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      backgroundColor: "#fff",
      borderColor: "#ced4da",
    }),
  }
  return (
    <div className="mb-3">
      <Label className="form-label">
        {label}
        {isRequire && <span className="text-danger">*</span>}
      </Label>
      <Select
        value={selectedDropdown}
        onChange={handleDropdown}
        options={optionGroup}
        className="select2-selection"
        styles={customStyles}
        placeholder={"Select " + label}
      />
      {validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

Dropdown.propTypes = {
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  optionGroup: PropTypes.array,
  isRequire: PropTypes.bool,
}

export default Dropdown
