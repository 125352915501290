import React, { useState } from "react"
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap"

// Formik Validation
import withRouter from "components/Common/withRouter"
import api from "constants/api"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb2"

import Password from "common/PageComponent/Password"
import labelConstant from "constants/label"
import messageConstant from "constants/message"
import { isEmpty } from "lodash"
import { addUpdateData } from "store/actions"

const ChangePassword = props => {
  //meta title
  document.title = "Change Password | " + process.env.REACT_APP_NAME

  const dispatch = useDispatch()
  const [updateChangePassword, setUpdateChangePassword] = useState("")

  const { data } = useSelector(state => ({
    data: state.AppMaster.data,
  }))

  const validationChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(
        messageConstant.OLD_PASSWORD_REQUIRED
      ),
      new_password: Yup.string().required(
        messageConstant.NEW_PASSWORD_REQUIRED
      ),
    }),
    onSubmit: values => {
      dispatch(addUpdateData(values, props.router.navigate, api.changePassword))
    },
  })

  if (
    !isEmpty(data) &&
    !isEmpty(data.userChangePassword) &&
    isEmpty(updateChangePassword)
  ) {
    setUpdateChangePassword(data.userChangePassword)
    localStorage.removeItem("leonAuthUserDetails")
    localStorage.removeItem("leonAuthUser")
    localStorage.removeItem("leonUserPermissions")
    props.router.navigate("/login")
  }

  const breadcrumbItem = [
    { title: "Home", path: "/dashboard" },
    { title: "Change Password", path: "/change-password" },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Change Password"
            breadcrumbItems={breadcrumbItem}
          />
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationChangePassword.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <Password
                          validation={validationChangePassword}
                          name="old_password"
                          label={labelConstant.OLD_PASSWORD}
                          isRequire={true}
                        />
                      </Col>
                      <Col md="12">
                        <Password
                          validation={validationChangePassword}
                          name="new_password"
                          label={labelConstant.NEW_PASSWORD}
                          isRequire={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mt-3 text-end">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChangePassword)
