const labelConstant = {
  EMAIL: "Email",
  OTP: "OTP",
  MOBILE: "Mobile",
  NAME: "Name",
  PASSWORD: "Password",
  FROM_DATE: "From Date",
  TYPE: "Type",
  TO_DATE: "To Date",
  TIMER: "Timer",
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  DESCRIPTION: "Description",
  ZIPCODE: "Zipcode",
  STATE: "State",
  CITY: "City",
  ADDRESS: "Address",
  LONGITUDE: "Longitude",
  LATITUDE: "Latitude",
  TITLE: "Title",
  POINTS: "Points",
  NO_OF_QR: "No of QR",
  PROFILE_IMAGE: "Profile Image",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  Description: "Description",
}
export default labelConstant
